import { useEffect, useState } from "react";

// Install
import { ContactSupport } from "@mui/icons-material";

// Components
import BoxWrapper from "../components/BoxWrapper";
import Manuals from "../components/Manuals";

// Services
import { Fetch } from "../services/Fetch";

function UserManual({ data }) {
    UserManual.displayName = "UserManual";

    const [loading, setLoading] = useState(true);
    const [manuals, setManuals] = useState([
        { name: "Instruktion för användare" },
        { name: "Instruktion för plats admin" },
        { name: "Instruktion för super admin" }
    ]);

    useEffect(() => {
        (async () => {
            try {
                const res = await Fetch(`https://sos-app.alvesta.se/manual/instructions`);
                if (res != null)
                    setManuals(res);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!data?.admin) {
            if (data?.employee?.support)
                setManuals(manuals.filter(x => !x.keywords?.include("admin")));
            else
                setManuals(manuals.filter(x => !x.keywords?.include("user")));
        } else
            return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, manuals])

    return (
        <BoxWrapper loading={loading}
            label="Manual"
            subLabel="Kommun SOS-app" img={<ContactSupport />}>
            <Manuals manuals={manuals}/>
        </BoxWrapper >
    )
}

export default UserManual;