
import { useEffect, useState } from 'react';

// Installed
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import MobileDetect from 'mobile-detect';
// import { useIsFocusVisible } from '@mui/material';
// import { useLocation } from 'react-router-dom';

// Components
import Loading from './components/Loading';

// Routes
import AuthorizedRoutes from './routes/AuthorizedRoutes';
import UnauthorizedRoutes from './routes/UnauthorizedRoutes';

// Functions
// import DateTicks from './functions/DateTicks';

// Services
import ApiRequest from './services/ApiRequest';

// Css
import './assets/css/index.css';

function App() {
  App.displayName = "App";

  const { instance, accounts } = useMsal();

  const [isMobileDevice, setMobileDevice] = useState(null);
  const [isBlockedPlatform, setBlockedPlatform] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentSession, setCurrentSession] = useState(sessionStorage.getItem("session"));
  const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem("token"));
  const [isRegistered, setRegistered] = useState(isAuthenticated);

  const isAuthorized = useIsAuthenticated();
  const mobileType = new MobileDetect(window.navigator.userAgent);

  useEffect(() => {

    // If device is a phone
    if (!!mobileType.phone()) {
      const platform = mobileType?.os()?.toString() === "AndroidOS" ? "android" : "ios";
      setMobileDevice(true);
      (async () => {
        await ApiRequest("data/app/url/" + platform).then(res => {
          if (res.status === 200 && !!res?.statusText !== "No Content")
            setBlockedPlatform({
              platform: platform,
              url: res?.data
            })
        }, error => {
          console.error(error)
        })
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isAuthorized && !isAuthenticated) {
      setCurrentSession(false);
      setLoading(false);
      return;
    }

    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized])

  const authenticate = async () => {
    if (!localStorage.getItem("municipality"))
      return;
    else if (isAuthenticated) {
      loginActions();
      return;
    }

    // Login automate if a valid active token is present
    if (!isBlockedPlatform && isAuthorized) {
      const loginRequest = {
        scopes: [JSON.parse(localStorage.getItem("municipality"))?.scope]
      }
      const request = {
        ...loginRequest, account: accounts[0]
        // ...loginRequest, prompt: 'login'
      };

      await instance.acquireTokenSilent(request).then((response) => {
        localStorage.setItem("ad-token", response.accessToken);
        loginActions(response.account);
      }, (e) => {
        // instance.acquireTokenPopup(request).then((response) => {
        instance.acquireTokenRedirect(request).then((response) => {
          localStorage.setItem("ad-token", response.accessToken);
          loginActions(response.account);
        });
      });
    }
  }

  const loginActions = async (model) => {
    sessionStorage.setItem("session", "true");
    setCurrentSession(true);
    setLoading(false);

    if (!model) return;

    await ApiRequest("authorization", "post", { name: model?.name, email: model?.username }).then(res => {
      if (res.status === 200 && res.data !== null) {
        localStorage.setItem("token", res.data.token);
        setAuthenticated(true);
        setRegistered(true);
      }
      setLoading(false);
    }, error => {
      console.error(error);
      setLoading(false);
    })
  }

  if (isAuthorized && !isAuthenticated)
    return <Loading>
      <p className="loading-content">Var god vänta, dina uppgifter kontrolleras ...</p>
    </Loading>;

  return (
    <div className={isAuthenticated ? 'container' : 'container-filled'}>
      {/* Content/Routes for unauthorized users or mobile device */}
      {((!isAuthenticated || !!isBlockedPlatform) && !currentSession && !loading) &&
        <UnauthorizedRoutes isBlockedPlatform={isBlockedPlatform} />}

      {/* Content/Routes for authorized users */}
      {(isAuthenticated && !isBlockedPlatform && !!currentSession) &&
        <AuthorizedRoutes
          isMobileDevice={isMobileDevice}
          mobileType={mobileType}
          isAuthorized={isAuthorized}
          isRegistered={isRegistered} />}

      {/* Loading */}
      {(!currentSession && loading) && <Loading />}
    </div>
  )
}

export default App; 