
import { useState } from "react";

// Installed
import { TextField } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";

// Components
import Loading from "./Loading";
import Response from "./Response";
import FormButtons from "./FormButtons";

function FormNotification({ title }) {
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState();
    const [confirm, setConfirm] = useState();

    const [send, response] = useOutletContext();

    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setConfirm(false);
        send(text);
    }

    const reset = () => {
        navigate(-1);
    }

    return <div className={`fade-in ${(response ? "response-wrapper" : "form-wrapper")}`}>
        {!response && <><h3>{title}</h3>
            <form onSubmit={onSubmit}>
                <TextField
                    name="text"
                    value={text}
                    placeholder="Din text här..."
                    multiline
                    rows={5}
                    disabled={confirm}
                    onChange={(e) => setText(e.target.value)} />

                {/* Buttons */}
                <FormButtons confirm={confirm}
                    disabled={!text || loading}
                    value="Skicka"
                    clickHandle={() => setConfirm(!confirm)} />
            </form>

            {loading && <Loading color="primary" size={35} />}
        </>}
        {response &&  <Response msg={response?.message} error={response?.error} inherit={true} reset={reset} />}
    </div>;
}

export default FormNotification;