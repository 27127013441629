                                                                                                                                                                                                                                                                                                               import { useEffect, useState } from "react";

// Installed
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Feed, PieChart } from "@mui/icons-material";

// Components
import BoxWrapper from "../components/BoxWrapper";

// Functions
import Municipality from "../functions/Municipality";

// Services
import ApiRequest from "../services/ApiRequest";


function Statistics() {
    Statistics.displayName = "Statistics"

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            await ApiRequest("data/statistics").then(res => {
                setLoading(false);
                setData(res.data)
            })
        }

        getData();
    }, [])

    return <BoxWrapper loading={loading} label="SOS-app statistik" subLabel={Municipality()} img={<PieChart />}>
        <List className="fade-in statistics-list-wrapper">
            {!!data && data?.map((item, index) => {
                return <ListItem key={index}>
                    <ListItemAvatar>
                        <Avatar variant="circular" className="avatar">
                            <Feed />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${item.title}: ${item.count}`} color="#cc0000" />
                </ListItem>
            })}
        </List>
    </BoxWrapper>;
}
export default Statistics;