// Installed
import { KeyboardReturn, Refresh } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Buttons({ children, useRefresh, refreshList, refresh }) {

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {
        if (!!refresh) refreshPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])

    const refreshPage = () => {
        if (useRefresh && !!refreshList)
            refreshList();
        else {
            var currentLocation = loc.pathname;
            navigate(loc.pathname + "/reload", { replace: true })
            setTimeout(() => {
                navigate(currentLocation, { replace: true })
            }, 0)
        }
    }

    return (
        <div className="buttons-container d-row">
            {!!children && children}

            {/* Reload button */}
            <Tooltip title="Uppdatera sidan" classes={{
                tooltip: "tooltip-default"
            }} enterDelay={1000} arrow>
                <IconButton onClick={refreshPage}>
                    <Refresh />
                </IconButton>
            </Tooltip>

            {/* Return button */}
            <Tooltip title="Gå tillbaka" classes={{
                tooltip: "tooltip-default"
            }} enterDelay={1000} arrow>
                <IconButton onClick={() => navigate(-1)}>
                    <KeyboardReturn />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default Buttons;