// Functions
// import Municipality from "./Municipality";

let notification;

// const municipality = Municipality();

function BrowserNotification(message) {
  if (!message?.img || message?.img === undefined)
    return;

  let municipality = JSON.parse(localStorage.getItem("municipality")); 
  const cleanedMessage = message.content.replaceAll("><", ">new.row<").replace(/<[^>]+>/g, '');
  try {
    // Desktop notification
    var options = {
      body: cleanedMessage.replaceAll("new.row", "\n") ?? "",
      icon: require(`./../assets/images/${message.img}.png`),
      dir: 'ltr'
    };

    notification = new Notification(municipality?.name + " SOS-app", options);

    setTimeout(() => {
       closeNotification();
    }, 5000)
  } catch (error) {
    console.info("View browser notification error: " + error);
  }
}

export default BrowserNotification;

function closeNotification() {
  notification.close();
}
