import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import Loading from "./Loading";

function Maps({ coords, apiKey }) {
    const [cords, setCords] = useState();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey
    })

    useEffect(() => {
        if (!!coords)
            setCords({
                lat: coords?.latitude,
                lng: coords?.longitude
            })
    }, [coords])

    return <div className="map-container">
        {isLoaded && <GoogleMap zoom={14} center={cords} mapContainerClassName="map-wrapper">
            <Marker position={cords} />
        </GoogleMap>}
        {!isLoaded && <Loading size={30} />}
    </div>
}

export default Maps;