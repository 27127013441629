import { useEffect, useState } from "react";
// import _ from 'lodash';

// Installed
import { Alert, Button, Checkbox, CircularProgress, FormControlLabel, FormLabel, IconButton, InputLabel, TextField, TextareaAutosize, Tooltip, styled } from "@mui/material";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Geocode from "react-geocode";
import { Delete, Help, PictureAsPdf, VideoFile } from "@mui/icons-material";

// Components
import Loading from "./Loading";
import Response from "./Response";
import FormButtons from "./FormButtons";

// Services
import ApiRequest from "../services/ApiRequest";

// Json
import inputs from './../assets/json/inputs.json';
import models from './../assets/json/models.json';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function Form({ title, value, activated, model }) {
    Form.displayName = "Form";

    const [department, params, data ] = useOutletContext();

    const [loading, setLoading] = useState(!!params.id);
    const [formData, setFormData] = useState(null);
    const [confirm, setConfirm] = useState();
    const [response, setResponse] = useState(null);
    const [fields, setFields] = useState([]);
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileError, setFileError] = useState();
    const [loaded, setLoaded] = useState([]);
    const [obj, setObject] = useState(null);
    const [objs, setObjects] = useState([]);

    const navigate = useNavigate();
    const loc = useLocation();
    const uriParams = useParams();

    useEffect(() => {
        Geocode.setApiKey(data?.apiKey);
    }, [data])

    useEffect(() => {
        if (!!params.inputs) {
            setFields(params.inputs);
            return;
        }
        
        setFields(inputs.filter(x => x.keys.indexOf(model) > -1));
        setFormData(models[model]);

        if (!!params && !!params?.id && loc.pathname.split("/").at(-1) !== "reload")
            getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc])

    const getData = async () => {
        await ApiRequest(params.api + "/" + (uriParams?.incidentId ?? params.id)).then(res => {
            setLoading(false);
            if (res.status === 200 && typeof res.data !== 'string') {
                let data = res.data;
                if (!!uriParams?.incidentId) {
                    setFormData({name: data?.name, text: data.messages[params.id], index: params.id});
                } else {
                    if (!!data?.phoneNumbers)
                        setObjects(data.phoneNumbers);
                    setFormData(data);
                    if (model === "manual") {
                        var arr = data?.videos;
                        if (!!data?.docLink)
                            arr.unshift(data?.docLink);
                        setFiles(arr);
                    }
                }

            } else
                setResponse(res);
        }, error => printError(error));

    }

    // #region Form handle
    const handleFormDataChange = (e) => {
        setFileError();
        if (!e.target) return;
        setFormData({ ...formData, [e.target.name]: e.target?.value });
    }

    // Update the form data object list if the seed exists with current changes
    const updateFormData = (e) => {
        let invalid = Object.values(obj).some(val => !val);
        let array = objs;
        if (!invalid)
            invalid = (array.indexOf(obj) > -1);

        if (invalid) {
            setObject(null);
            return;
        }

        array.push(obj);
        setObjects(array)
        setFormData({ ...formData, [e.target.name]: array });
        setObject(null);
    }

    // Delete an item from form data variable
    const deleteFormDataItem = (item, name) => {
        let arr = formData[name];
        const updatedArray = arr.filter((x, ind) => ind !== arr.indexOf(item));
        setObjects(updatedArray);
        setFormData({ ...formData, [name]: updatedArray });
    }
    // #endregion

    // #region File upload
    const uploadFile = (e) => {
        e.preventDefault();

        setFileError();

        if (e.target.files && e.target.files?.length > 0) {

            const uploadedFile = e.target.files[0];

            // Checks if this file has already been downloaded
            if (!!loaded.find(x => x.name === uploadedFile.name)) {
                setFileError(`Den här filen har redan laddats`);
                return;
            }

            const format = uploadedFile.type;
            const fileType = e.target.name;

            // Check file format and size
            if (uploadedFile.size > 104857600) {
                setFileError("Filstorleken är mer än 100 mb");
                return;
            } else if (format.indexOf(fileType) === -1) {
                setFileError(`Fel filtyp.Tillåten filtyp är ${fileType}`);
                return;
            }

            setFile(fileType);
            const reader = new FileReader();
            reader.onload = (e) => {
                saveFile(uploadedFile, fileType);
            };
            reader.readAsDataURL(uploadedFile);
        }
    }

    const deleteFile = (name) => {
        setFiles(files.filter((x, ind) => ind !== files.indexOf(name)));
        if (loaded?.length > 0)
            setLoaded(loaded.filter(x => x?.saved !== name));
    }

    // Upload file
    const saveFile = async (uploadedFile, fileType) => {
        let data = new FormData();
        data.append("uploadedFile", uploadedFile);

        await ApiRequest(`${params?.api}/uploadFile/${formData?.keywords}`, "post", data).then(res => {
            let listFiles = files;

            if (res.status === 200 && listFiles.indexOf(res.data) === -1) {
                setLoaded(oldArray => [...oldArray, { name: uploadedFile.name, saved: res.data }]);
                if (fileType === "video")
                    listFiles.push(res.data);
                else
                    listFiles.unshift(res.data);
                setFiles(listFiles);
                setFile();
            } else
                setResponse(res)
        }, error => printError(error));
    }

    // #endregion

    // #region Submit
    const confirmHandle = () => {
        setConfirm(!confirm);
        if (!!formData?.address && !confirm) {
            Geocode.fromAddress(formData?.address).then(res => {
                const coords = res.results[0].geometry.location;
                if (!!coords) {
                    setFormData({
                        ...formData,
                        latitude: coords.lat,
                        longitude: coords.lng
                    });
                }
            }, error => console.log(error));
        }
    }
    // Submit data
    const onSubmit = async (e) => {
        e.preventDefault();
        setConfirm(false);
        setLoading(true);

        let data = formData;

        if (data?.department === null)
            data.department = department?.name;
        if (data.departmentId === null)
            data.departmentId = department?.id;
        if (data.id === null)
            data.id = uriParams?.incidentId;
        if (data.blockerId === null)
            data.blockerId = data?.employee?.id;

        if (params?.api === "manual") {
            let uploaded = files;
            if (files?.length > 0 && files[0].indexOf(".pdf") > -1) {
                data.docLink = files[0];
                uploaded = uploaded.slice(1);
            } else
                data.docLink = null;

            data.videos = uploaded;
        }

        let request = (uriParams?.incidentId || params.id) 
            ? ApiRequest(`${params?.api}/${(uriParams?.incidentId ?? params.id)}`, "put", data)
            : ApiRequest(params.api, "post", data);

        if (!!uriParams?.blockId)
            request = ApiRequest("blocked", "post", data);

        await request.then(res => {
            setLoading(false);
            if (res.status === 200 && (res.data?.length === 0 || res.data === true)) {
                setResponse(res);
                setFormData(data);
            } else
                setResponse({ message: res?.data?.message || res?.data, error: true });
        }, error => printError(error));
    }

    const printError = (error) => {
        setLoading(false);
        setFile();
        setFileError(error);
        console.error(`Form error: => ${error}`)
    }
    // #endregion


    if (!!response)
        return <Response msg={response?.message} error={response?.error} reset={() => setResponse(null)} />;

    return <div className="fade-in form-wrapper">
        <h3>{title}</h3>

        {/* Notification content */}
        {loc.pathname.indexOf("notification") > -1 && <div className="d-column notification-content">
            <h4>{formData?.incident}</h4>
            <p className="d-column">{formData?.text}</p>
        </div>}

        {!!formData && <form onSubmit={onSubmit}>

            {fields?.map((inp, ind) => {

                // var helpText = inp?.helpText || paramHelpText;
                var disabled = (!!activated && activated.indexOf(inp?.name) === -1) || confirm;

                // eslint-disable-next-line no-eval
                if (!!inp?.paramVisible && !eval(inp?.paramVisible)) return null;

                // Checkbox
                if (inp?.checkbox) {
                    const label = !!inp?.checkbox ? inp?.label : "Aktivera";
                    return <p className="d-row" key={ind} style={{ justifyContent: "space-between", margin: "3px 0" }}>
                        <FormControlLabel
                            id="checkbox"
                            control={<Checkbox size="medium"
                                checked={formData[inp.name] ?? false}
                                disabled={disabled}
                                onChange={() => setFormData({
                                    ...formData, [inp.name]: !formData[inp.name]
                                })} />} label={label} />

                        {!!inp.helpText && <Tooltip
                            title={inp?.helpText?.replace("{department}", department?.name)}
                            classes={{
                                tooltip: "tooltip-default"
                            }}
                            enterDelay={100} arrow>
                            <Help color="primary" />
                        </Tooltip>}
                    </p>
                } else if (!!inp.textarea) {
                    return <div className='form-textarea-wrapper' key={ind}>
                        {!!inp?.label && <FormLabel className="form-textarea-label">{inp?.label} *</FormLabel>}
                        <TextareaAutosize
                            className="form-textarea"
                            label={inp?.label}
                            name={inp?.name}
                            required={!!inp?.required}
                            id={inp?.name}
                            defaultValue={(!!formData && formData[inp.name]) || ""}
                            placeholder={inp?.placeholder || "Din text här..."}
                            minRows={!!inp?.multiline ? 6 : 1}
                            disabled={disabled}
                            helpertext={(inp?.helpText && formData?.keywords === inp.helpVisible) ? inp?.helpText : ""}
                            onChange={handleFormDataChange}
                        />
                    </div>
                    // eslint-disable-next-line no-eval
                } else if (!inp.file && !!inp?.objects) {

                    return <div className="d-column w100" key={ind}>

                        <InputLabel className="d-row row-label">
                            {inp?.label}

                            <Tooltip
                                title={inp?.helpText?.replace("{department}", department?.name)}
                                classes={{
                                    tooltip: "tooltip-default"
                                }}
                                enterDelay={100} arrow>
                                <Help color="primary" />
                            </Tooltip>
                        </InputLabel>

                        <div className="d-row w100" style={{ justifyContent: "space-between" }}>
                            {inp?.objects.map((item, ind) => {
                                return <TextField
                                    key={ind}
                                    className="form-fields"
                                    style={{ width: "49%" }}
                                    label={item.label}
                                    name={item.name}
                                    required={true}
                                    id={item.name}
                                    multiline={inp?.multiline || false}
                                    value={(!!obj && obj[item.name]) || ""}
                                    placeholder={inp?.placeholder || "Din text här..."}
                                    disabled={disabled}
                                    onChange={(e) => setObject({ ...obj, [item.name]: e.target.value })} />
                            })}
                        </div>

                        {/* Loop of form data value */}
                        {objs.length > 0 && objs?.map((i, index) => {
                            return <div key={index} className="list-div d-row" >
                                <p className="link">• {i?.name} {i?.number}</p>
                                <IconButton color="error" onClick={() => deleteFormDataItem(i, inp.name)} size="small">
                                    <Delete />
                                </IconButton>
                            </div>
                        })}

                        {/* Save form data value*/}
                        <Button disabled={obj === null || Object.keys(obj)?.length < inp.objects.length || Object.values(obj).some(val => !val)}
                            name={inp.name} onClick={updateFormData}
                            style={{ alignSelf: "flex-end" }}>
                            Spara {inp.label}
                        </Button>
                    </div>

                    // eslint-disable-next-line no-eval
                } else if (!inp.file && (inp?.visible === undefined || !!eval(inp?.visible)))
                    return <TextField
                        key={ind}
                        className="form-fields"
                        label={inp?.label}
                        name={inp?.name}
                        required={!!inp?.required}
                        id={inp?.name}
                        multiline={inp?.multiline || false}
                        value={(!!formData && formData[inp.name]) || ""}
                        placeholder={inp?.placeholder || "Din text här..."}
                        disabled={disabled || (inp?.disabled && !!params?.id) || !!uriParams?.blockId}
                        onChange={handleFormDataChange}
                        helpertext={(inp?.helpText && (formData?.keywords === inp.helpVisible || !inp.helpVisible)) ? inp?.helpText : null} />
                else
                    return null;
            })}

            {/* Only for incident */}

            {/* Only for manual manage */}
            {model === "manual" &&
                <div className="d-column">
                    {/* Uploaded */}
                    {files?.length > 0 && <div className="list-wrapper" style={{ margin: "20px 0 25px 0" }}>
                        {files?.map((item, index) => {
                            return <div key={index} className="list-div d-row" >
                                <p className="link" onClick={() => navigate(`/manuals/${item}`)}>• {item}</p>
                                <IconButton color="error" onClick={() => deleteFile(item)} size="small">
                                    <Delete />
                                </IconButton>
                            </div>
                        })}
                    </div>}

                    {/*  Error */}
                    {!!fileError && <Alert color="error" variant="outlined"
                        style={{ width: "-webkit-fill-available", marginBottom: "15px" }}
                        onClose={() => setFileError()}>{fileError}</Alert>}

                    {/* Upload button */}
                    <div className="d-row" style={{ justifyContent: "space-between" }}>
                        {[{ label: "pdf dokument", format: "pdf", accept: "application/pdf", icon: <PictureAsPdf /> },
                        { label: "video", format: "video", accept: "video/*", icon: <VideoFile /> }].map((item, ind) => {
                            return <Button component="label"
                                className="d-row"
                                disabled={!formData?.keywords || !!file}
                                style={{ width: "49%" }}
                                key={ind} variant={file !== item?.format ? "outlined" : "contained"} startIcon={item?.format === file ? <CircularProgress size={16} color="inherit" /> : item.icon}>
                                {item?.label}
                                <VisuallyHiddenInput type="file" accept={item.accept} name={item.format} onChange={uploadFile} />
                            </Button>
                        })}
                    </div>
                </div>}

            {/* Buttons */}
            <FormButtons confirm={confirm}
                disabled={loading || (!!obj && !Object.values(obj).some(val => !val) && objs.indexOf(obj) === -1)}
                value={value || "Spara"}
                clickHandle={confirmHandle} />
        </form>}

        {(loading || !formData) && <Loading color="primary" size={35} />}
    </div>;
}

export default Form;