// Installed
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";

function Modal({open, res, msg, content, buttonValue, closeButtonValue, clickHandle, close}) {

    const error = !!res && (res?.status !== 200 || !!res?.data?.error || (res?.status === 200 && typeof res?.data === "string"));

    return (
            <Dialog open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                color={res?.status === 200 ? "inheit" : "error"}
                sx={{
                    zIndex: 3000,
                    minWidth: 350
                }}
            >
                <DialogTitle id="alert-dialog-title" color={error ? "#cc0000" : "-moz-initial"}>
                    {msg ?? (!error ? "Skickad!" : "Obs!")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {res?.data?.msg ||content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!!buttonValue && <Button color="error" onClick={clickHandle}>{buttonValue}</Button>}
                    <Button onClick={close} color="inherit" autoFocus>
                       {!!closeButtonValue ? closeButtonValue : "Stänga"}
                    </Button>
                </DialogActions>
            </Dialog>
  )
}

export default Modal;