const redirectUrl = window.location.href;
const stored = localStorage.getItem("municipality");
export const config = {
    auth: {
        clientId: !!stored ? JSON.parse(stored)?.clientId : null,
        redirectUri: redirectUrl,
        authority: "https://login.microsoftonline.com/alvesta.onmicrosoft.com",
        postLogoutRedirectUri: redirectUrl,
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = {
//     scopes: [JSON.parse(localStorage.getItem("municipality"))?.scope]
// };