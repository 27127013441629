import { useState, useEffect } from 'react';
import _ from 'lodash';

// Installed
import { FormControlLabel, Radio, TextField, TextareaAutosize } from '@mui/material';

// Components
import Buttons from '../components/Buttons';
import ListSelect from '../components/ListSelect';
import FormButtons from '../components/FormButtons';
import Loading from '../components/Loading';
import Response from '../components/Response';

// Services
import ApiRequest from '../services/ApiRequest';

// Json
import models from "../assets/json/models.json"
import { useNavigate } from 'react-router-dom';

function SendNotificationMail({ model, label, api }) {
    SendNotificationMail.displayName = "SendNotificationMail";

    const [formData, setFormData] = useState(models[model]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [response, setResponse] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        setFormData(models[model]);
        setSelected([]);
        setConfirm(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])

    const handleSelectValueChange = (value) => {
        setFormData({
            ...formData, municipalities: value
        });
        setSelected(value);
    }

    const changeHandle = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        });
    }

    const handleVariants = (e, enter) => {

        let value = e.target.value;
        setFormData({ ...formData, [e.target.name]: value?.replace(",", "\n") });

        let valueArray = [];
        if (value?.indexOf("\n") > -1)
            valueArray = value.split("\n");
        else if (value?.indexOf(",") > -1)
            valueArray = value.split(",")
        else if (enter)
            valueArray.push(value);

        if (valueArray.length > 0)
            setSelected(valueArray);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setConfirm(false);
        setLoading(true);

        let data = formData;
        if (model === "mail") {
            if (selected?.length > 0) {
                delete data.emails;
                data.emails = selected;
            } else
                data.emails = formData?.emails.split(",");
        }

        await ApiRequest(api, "post", data).then(res => {
            setLoading(false);
            setResponse(res?.data ?? res);
        }, error => printError(error))
    }

    const printError = (error) => {
        if (error.response.status === 401)
            navigate("/session/expired");
        setLoading(false);
        setResponse({ message: error, error: true });
        console.error(`Push notification error: => ${error}`)
    }

    return (
        <div className="container-inside">
            <div className="box d-column">
                <div className="box-menu d-row">
                    {/* Heading */}
                    <div className="box-header-wrapper">
                        <h3>Skicka {label}</h3>
                    </div>

                    {/* Buttons wrapper */}
                    <Buttons useRefresh={true} refreshList={() => setRefresh(true)}></Buttons>
                </div>

                {!!response && <Response msg={response?.message} success={response.success} error={response?.error} reset={() => setResponse(null)} />}

                {!response && <div className="fade-in form-wrapper">
                    <h3 className='d-column flex-start' style={{ marginBottom: "30px" }}>
                        <span>Meddelande</span>
                        <span style={{ fontSize: "12px" }}>{selected.join(",")}</span>
                    </h3>

                    <form onSubmit={onSubmit}>
                        {/* Select list with departments */}
                        {model === "notice" && <ListSelect
                            api="municipality"
                            reload={refresh}
                            name="name"
                            matchParameter="name"
                            label="Mottagare"
                            required={true}
                            selected={selected}
                            multiple={true}
                            handleChange={handleSelectValueChange}
                            disabled={loading}
                            width="100%" />}

                        {model === "mail" && <TextField
                            name="emails"
                            label="Mottagare (e-postadress)"
                            className="form-fields"
                            id="mailsfield"
                            placeholder='Separera med kommatecken eller tryck Enter ...'
                            required
                            multiline
                            value={formData.emails}
                            disabled={loading}
                            onKeyUp={(e) => {
                                if (e.key === "Enter")
                                    handleVariants(e, true);
                            }}
                            onChange={handleVariants}
                        />}

                        <TextField
                            name="title"
                            label="Title"
                            className="form-fields"
                            id="textfield"
                            placeholder='Din titel här..'
                            required
                            value={formData?.title}
                            disabled={loading}
                            onChange={changeHandle}
                        />

                        {model === "notice" && <TextField
                            name="link"
                            label="Länk"
                            className="form-fields"
                            id="textlink"
                            placeholder='Din länk här..'
                            required
                            value={formData?.link}
                            disabled={loading}
                            onChange={changeHandle}
                        />}

                        <div className='form-textarea-wrapper'>
                            <TextareaAutosize
                                className="form-textarea"
                                label="Meddelande"
                                name="content"
                                required
                                id="textarea"
                                defaultValue={formData.text}
                                placeholder="Din text här..."
                                minRows={5}
                                disabled={loading}
                                onChange={changeHandle}
                            />
                        </div>

                        {model === "notice" && <div className='d-column flex-start'>
                            {["", "iOS", "Android"].map((value, ind) => {
                                return <FormControlLabel
                                    id="checkbox"
                                    key={ind}
                                    control={<Radio
                                        size="medium"
                                        disabled={loading}
                                        checked={formData?.platform === value}
                                        onChange={() => setFormData({
                                            ...formData, platform: value
                                        })} />} label={value !== "" ? value : "Alla enheter"} />
                            })}
                        </div>}

                        {/* Buttons */}
                        <FormButtons confirm={confirm}
                            disabled={_.isEqual(models[model], formData) || loading}
                            value="Skicka"
                            clickHandle={() => setConfirm(true)} />
                    </form>

                    {loading && <Loading color="primary" size={35} />}
                </div>}
            </div>
        </div>
    )
}

export default SendNotificationMail;