
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Installed
import { useMsal } from "@azure/msal-react";

// Components
import Loading from "../components/Loading";
import ExpiredSession from '../components/ExpiredSession';

// Services
import { config } from "../services/Config";

function Logout({ isAuthorized, expired }) {

    const { instance } = useMsal();
    const navigate = useNavigate();

    const logoutRequest = {
        account: instance.getAccountByHomeId(config.auth.clientId),
        mainWindowRedirectUri: config.auth.redirectUri
    };

    useEffect(() => {
        if(!!expired)
            return;

        localStorage.removeItem("token");
        localStorage.removeItem("ad-token");
        localStorage.removeItem("employeeEmail");
        sessionStorage.removeItem("currentDepartment");

        setTimeout(() => {
            sessionStorage.removeItem("session");
            if (isAuthorized) {
                instance.logoutRedirect(logoutRequest).catch((e) => {
                    console.error(e);
                });
            } else
                window.location.href = "/";
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


if (!!expired)
    return <ExpiredSession navigate={navigate}/>;

return <Loading>
    <p className="loading-content">Du loggas ut ...</p>
</Loading>;
}

export default Logout;