
import { useEffect, useState } from "react";

// Installed
import {
    Add, AppRegistration, Close, Delete, DevicesOther, Download, Edit,
    EventAvailable, EventBusy, EventRepeat, ForwardToInbox, InfoOutlined, MarkEmailRead,
    MobileFriendly, MobileOff, Search, SearchOff, Update, Warning, Web, WebAssetOff
} from "@mui/icons-material";
import { Alert, AlertTitle, Button, CircularProgress, IconButton, Pagination, Switch, TextField, Tooltip } from "@mui/material";
import { Outlet, useNavigate, useParams, useLocation, Link } from "react-router-dom";

// Components
import Loading from "../components/Loading";
import ListSelect from "../components/ListSelect";
import FormButtons from "../components/FormButtons";
import Buttons from "../components/Buttons";

// Functions
import { DecodedToken } from "../functions/DecodeToken";
import Municipality from "../functions/Municipality";

// Services
import ApiRequest from "../services/ApiRequest";
import { Fetch } from "../services/Fetch";

function Settings({ api, label, link, mail, connection, data, accessible, visible, search, subText, count, download, hideButtons }) {
    Settings.displayName = "Settings";

    // #region Variables
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [heading, setHeading] = useState(label);
    const [department, setDepartment] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [itemOffset, setItemOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [hidden, setHidden] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [error, setError] = useState();
    const [admin, setAdmin] = useState(false);
    const [isSoonExpire, setSoonExpire] = useState();
    const [access, setAccess] = useState(false);
    const [model, setModel] = useState(null);
    const [reminderIds, setReminderIds] = useState([]);
    const [request, setRequest] = useState(null);
    const [sort, setSort] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [searchVisible, setSearchVisible] = useState(false);

    const perPage = 10;
    const endOffset = itemOffset + perPage;
    const municipality = Municipality();

    const navigate = useNavigate();
    const loc = useLocation();
    const { incidentId, viewId, editId, paramId, blockId, searchId, action, update, param } = useParams();
    const pathname = "/settings/" + loc.pathname.split("/")[2];
    // #endregion

    // #region UseEffects
    useEffect(() => {
        if (!!data && !data.employee.support)
            navigate(-1);

        setDepartment(data?.department);
        setEmployee(data?.employee);
        setDepartmentId(data?.department?.id)
        setAdmin(data?.admin);

        // The control over Azure's secret key to recognizing the validity date soon expired or not. 
        // The response will not be Null if there are less than three validity days.
        (async () => {
            await ApiRequest(`data/checkKeysValidity`).then(res => {
                if (!!res.data)
                    setSoonExpire(res.data);
            })
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        setHidden(action || editId || viewId || update || blockId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, editId, viewId, update, blockId])

    useEffect(() => {
        if (!!paramId)
            setDepartmentId(paramId);
    }, [paramId])

    useEffect(() => {
        if (!!searchId) {
            setSearchVisible(true);
            async function getBySearchKeyword() {
                await ApiRequest(`${api}/search/${searchId}`).then(res => {
                    setLoading(false);
                    setList(res.data);
                    setSearchKeyword("");
                }, error => printError(error));
            }

            getBySearchKeyword();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchId])

    useEffect(() => {
        if (action || editId || !departmentId || searchId)
            return;

        setRefresh(false);
        setList([]);
        setModel(null);
        setDeleteId(null);
        setConfirm(false);
        setHeading(label);
        setPage(1);
        setItemOffset(0);
        setAccess(admin || accessible);

        if (!!incidentId)
            getIncidentMessages();
        else if (["department", "logs", "municipality", "data", "manual"].indexOf(api) === -1) {
            if (department?.name === municipality)
                setDepartment(data?.department)

            let requestApi = (!!param ? `${api}/${param}` : api) + `/department/${paramId || department.id}`;
            getTableList((!paramId && !department) ? api : requestApi);
        } else if (api === "department") {
            setDepartment({ ...department, name: municipality });
            getTableList("department/suitable/" + DecodedToken().upn);
        } else {
            setDepartment({ ...department, name: "Kommun SOS-app" });
            getTableList(api);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc, refresh, incidentId, departmentId])

    useEffect(() => {
        if (!!error) {
            setTimeout(() => {
                reset();
            }, 50000)
        }
    }, [error])
    // #endregion

    const getTableList = async (api) => {
        setList([]);
        setLoading(true);
        await ApiRequest(api).then(res => {
            setLoading(false);
            setList(res?.data);
        }, error => printError(error));
    }

    const getIncidentMessages = async () => {
        setLoading(true);
        await ApiRequest(`${api}/${incidentId}`).then(response => {
            const res = response.data;
            setLoading(false);
            setHeading(res?.name);
            setList(res?.messages);
        }, error => printError(error));
    }

    const getTableListLaidOffEmployees = () => {
        if (!sort) {
            setSort(true);
            getTableList(`${api}/getLaidOff/${department?.id}`);
        } else
            setRefresh(true)
    }

    const handleSelectValueChange = async (value) => {
        if (!!paramId)
            navigate(loc.pathname.replace("/sort/" + paramId, ""), { replace: true });
        setSort(false);

        setDepartment(value);
        setDepartmentId(value?.id);
    }

    const confirmHandle = (id) => {
        setDeleteId(id);
        setConfirm(!confirm);
    }

    const downloadFile = async (url, id) => {
        setDeleteId(id);
        const fileDownload = require('react-file-download');
        await ApiRequest(url + id).then(res => {
            if (res.status === 200)
                fileDownload(res.data?.logText, res.data?.fileName);
            setDeleteId(null);
        }, error => {
            console.error("Download log file => " + error);
            if (error.response.status === 401)
                navigate("/session/expired");
        })
    }

    const navigateTo = (id) => {
        if (!link) return;

        if (!!param)
            navigate(`${link}/${param}/${id}`);
        else
            navigate(`${link}/${id}`);
    }

    const updateItem = async (item, param, isList) => {

        let value = typeof item[param] === "boolean"
            ? !item[param]
            : (item[param] === "true" ? "false" : "true");


        await ApiRequest(`${api}/${item?.id}`, "patch", [{ value: value, path: `/${param}`, op: "replace" }])
            .then(res => {
                if (api === "department") {
                    if (res.status === 200 && !isList)
                        setDepartment({ ...department, allowCallSwitch: value });

                    if (item?.id === employee?.departmentId) {
                        sessionStorage.removeItem("currentDepartment");
                        navigate(0);
                    }
                }
            }, error => printError(error));

        if (isList) {
            let newList = [...list];
            newList[list.indexOf(item)][param] = value;
            setList(newList);
        }
    }

    const deleteItem = async () => {
        setConfirm(false);
        setRequest({
            id: deleteId,
            action: "delete"
        });
        let requestApi = api.indexOf("/") > -1 ? api.slice(0, api.indexOf("/")) : api;

        if (incidentId) {
            const filteredList = list?.filter((x, index) => index !== deleteId);
            await ApiRequest(`${requestApi}/${incidentId}`, "put", { name: heading, messages: filteredList }).then(res => {
                if (res.status === 200)
                    setList(filteredList)
                else {
                    setError(res.data);
                    console.warn(res.data)
                }
            }, error => {
                console.error(error);
                if (error.response.status === 401)
                    navigate("/session/expired");
            });
        } else {
            if(!!param)
                requestApi += "/" + param;
            await ApiRequest(`${requestApi}/${deleteId}`, "delete").then(res => {
                setRequest(null);
                if ([200, 204].indexOf(res?.status) > -1) {
                    if (res.data === "reload") {
                        sessionStorage.removeItem("currentDepartment"); // If the user self has deleted the own user's account
                        window.location.href = "/";
                    } else if (!res.data || res.data === "") {
                        // Update current list
                        const filteredList = list?.filter(x => x.id !== deleteId);
                        setList(filteredList);
                        if (((filteredList?.length / perPage) + 1) <= page) { // Return to the lower page number if the current page is empty after deleting
                            const newOffset = (((page - 1) - 1) * perPage);
                            setItemOffset(newOffset);
                            setPage(page - 1);
                        }
                        setTimeout(() => {
                            setDeleteId(null);
                        }, 100)
                    } else
                        setError(res.data);
                } else {
                    setError(res.data);
                    console.warn(res.data)
                }
            }, error => {
                console.error(error);
                if (error.response.status === 401)
                    navigate("/session/expired");
            });
        }

    }

    const sendRemindEmail = async (id) => {
        setRequest({
            id: id,
            action: "email"
        });
        await ApiRequest(`${api}/reminder/mail/${id}`).then(res => {
            if (!!res.data.error)
                printError(res.data.error);
            else {
                setReminderIds([...reminderIds, id]);
                setRequest(null);
            }
        }, error => printError(error));
    }

    const searchHandle = () => {
        setSearchVisible(!searchVisible);
        setSearchKeyword("");

        if (searchVisible && !!searchId)
            navigate("/settings/employees");
    }

    const handlePageChange = (e, value) => {
        setModel(null);
        const newOffset = ((value - 1) * perPage);
        setItemOffset(newOffset);
        setPage(value);
    }

    const printError = (error) => {
        if (error.response.status === 401)
            navigate("/session/expired");
        setLoading(false);
        setRequest(null);
        console.error(`Get list ${api} => ${error}`)
    }

    const getInfo = async (api, item, close) => {
        if (!!model)
            setModel(null);
        if (close) return;

        let count = 0;
        if (!!connection && api === "department") {
            connection?.on("ConnectedEmployeesByDepartment", (users) => {
                count = users?.length;
            })
            await connection?.invoke("ConnectedByDepartment", item?.name);
        } else if (api === "municipality") {
            const res = await Fetch(`https://${item?.hostName}/${api}/statistic/by/${(new Date()).toISOString().substring(0, 10)}`);
            if (res !== null) {
                item.employeesCount = res?.employeesCount;
                item.devicesCount = res?.devicesCount;
                setModel({ ...model, item: item })
            }
            return;
        }

        setTimeout(async () => {
            setModel({
                ...model,
                item: item,
                webConnected: count
            })
        }, 100)
    }

    const reset = () => {
        setError();
        setDeleteId(null);
    }

    return <div className="container-inside">
        <div className="box d-column">

            {/* Panel */}
            <div className="box-menu d-row">
                {/* Heading */}
                <div className="box-header-wrapper">
                    <h3 className="d-row">{!!label ? heading : (param === "warnings" ? "Varningar" : "Intern meddelande")}</h3>
                    <p>{subText ?? department?.name}</p>
                </div>

                {/* Buttons wrapper */}
                <Buttons useRefresh={!action && !viewId && !editId} refreshList={() => setRefresh(true)}>
                    {/* Lägga till en ny */}
                    {visible && <Tooltip title="Lägg till en ny"
                        classes={{
                            tooltip: "tooltip-default"
                        }} enterDelay={1000} arrow>
                        <IconButton color={action ? "error" : "primary"} onClick={() => navigate("add")} >
                            <Add />
                        </IconButton>
                    </Tooltip>}

                    {/* Search button */}
                    {(search && !hidden) && <Tooltip title="Sök anställd"
                        classes={{
                            tooltip: "tooltip-default"
                        }} enterDelay={1000} arrow><IconButton onClick={searchHandle}>
                            {searchVisible ? <SearchOff color="error" /> : <Search />}
                        </IconButton>
                    </Tooltip>}
                </Buttons>
            </div>

            {/* Alert if secret key validity days expires soon */}
            {(isSoonExpire && !update) && <Alert severity="error" color="error" className="alert-wrapper">
                <AlertTitle style={{ fontWeight: 600 }}>Updatera hemliga nycklar</AlertTitle>
                <div dangerouslySetInnerHTML={{ __html: isSoonExpire }}></div>
                {admin && <Link to="/subText/data/update/keys" className="d-row" underline="none">
                    <Update /> Uppdatera nyckelord
                </Link>}
            </Alert>}

            {/* Search, search & count*/}
            {!hidden && <div className="menu-div d-row">
                <h4 style={{ paddingLeft: "10px" }}>Antal: {list?.length || 0}</h4>

                {(!incidentId && department && (searchVisible || !subText)) &&
                    <div className="d-row" style={{ width: 'max-content' }}>

                        {/* Buttons to get laid off employees */}
                        {(api === "employee" && (list?.length > 0 || sort) && !searchId) &&
                            <Tooltip title={`Visa registrade anställda vilka jobbar inte längre på ${department?.name}`}
                                classes={{
                                    tooltip: "tooltip-default"
                                }} enterDelay={1000} arrow>
                                <IconButton style={{ marginRight: 10 }} onClick={getTableListLaidOffEmployees}>
                                    {(sort && list?.length > 0) ? <EventAvailable color="warning" />
                                        : (list?.length === 0 ? <EventBusy color="disabled" /> : <EventRepeat />)}
                                </IconButton>
                            </Tooltip>}

                        {/* Search employee */}
                        {searchVisible && <div className="search-wrapper">
                            <TextField
                                className='search-input'
                                label="Sök anställd"
                                value={searchKeyword}
                                placeholder="Minst tre tecken ..."
                                onChange={(e) => setSearchKeyword(e.target?.value)}
                                inputProps={{
                                    maxLength: 30
                                }}
                            />
                            <IconButton disabled={searchKeyword?.length < 3} color="primary"
                                onClick={() => navigate("/settings/employees/search/" + searchKeyword)}>
                                <Search />
                            </IconButton>
                        </div>}

                        {/* Select list to sort map list */}
                        {!searchVisible && <ListSelect
                            api="department"
                            disabled={["data", "department", "logs", "manual"].indexOf(api) > -1 || !admin}
                            loading={loading}
                            reload={loc || refresh}
                            name="name"
                            sort={param === "internal" ? data?.employee?.connectedDepartments : null}
                            param="id"
                            matchParameter="id"
                            count={count}
                            search={search}
                            warning={api === "notification"}
                            label="Sortera med arbetsplats"
                            selected={paramId || department?.id}
                            handleChange={handleSelectValueChange} />}
                    </div>}
            </div>}

            {/* Printed list table */}
            {(!loading && !hidden) && <div className="fade-in list-wrapper">
                {/* Loop list */}
                {list?.length > 0 && list?.slice(itemOffset, endOffset)?.map((item, index) => {

                    const close = model?.item?.id === item?.id;
                    const infoButtonContent = <Tooltip title={close ? "Stänga information fönster" : `Mer information om ${item?.name}`}
                        classes={{
                            tooltip: "tooltip-default"
                        }} enterDelay={1000} arrow>
                        {close ? <Close color="error" /> : <InfoOutlined />}
                    </Tooltip>;

                    return <div key={index} className={"table-row d-row" + (((!!error || confirm) && deleteId !== item?.id) ? " hidden" : "")}>

                        {/* List item index */}
                        <p className="d-row" style={{ width: "auto" }}>
                            {(itemOffset + index + 1)}
                        </p>

                        {/* List item name */}
                        <p className={"list-item" + (deleteId === item?.id ? " disabled-block" : "")}>

                            {/* Employee count */}
                            {api === "department" &&
                                <Tooltip title={`Antal registrerad anställda ${item?.employeesCount} person(er).`}
                                    classes={{
                                        tooltip: "tooltip-default"
                                    }} enterDelay={1000} arrow>
                                    <span>
                                        <Button variant={item?.employeesCount > 0 ? "contained" : "outlined"} className="btn-count"
                                            onClick={() => navigate("/settings/employees/sort/" + item?.id)}
                                            disabled={item?.employeesCount === 0 || false}>{item?.employeesCount}</Button>
                                    </span>
                                </Tooltip >}

                            {/* Item name */}
                            <span className={(!link ? "no-link" : "")}
                                onClick={() => navigateTo(item?.id)}>
                                <span dangerouslySetInnerHTML={{ __html: (item?.name || item) }}></span>
                                {!!item?.date && <span>{item?.date}</span>}
                            </span>
                        </p>

                        {/* List buttons */}
                        {((!confirm && !error) || deleteId !== item?.id) &&
                            <div className="table-row-buttons d-row">

                                {/* Department & employee actions */}
                                {(!!mail && item?.remind) && <IconButton onClick={sendRemindEmail.bind(this, item?.id)} disabled={!!request}>
                                    <Tooltip title={`Skicka ett e-postpåminnelsen till anställd(a) på ${item?.name} som inte har laddat ner appen ännu`}
                                        classes={{
                                            tooltip: "tooltip-default"
                                        }} enterDelay={1000} arrow>
                                        {(request?.id === item?.id && request?.action === "email") ? <CircularProgress size={17} color="inherit" /> :
                                            (reminderIds.indexOf(item?.id) > -1 ? <MarkEmailRead color="success" /> : <ForwardToInbox color="inherit" />)}
                                    </Tooltip>
                                </IconButton>}

                                {/* Department actions */}
                                {api === "department" && <>
                                    {item?.activeAlarm > 0 && <Tooltip title={`Antal warningar ${item?.activeAlarm} på ${item?.name} under senaste 30 dagar`}
                                        classes={{
                                            tooltip: "tooltip-default"
                                        }} enterDelay={1000} arrow>
                                        <IconButton color="error" onClick={() => navigate("/settings/notifications/warnings/sort/" + item?.id)}>
                                            <Warning color="error" />
                                        </IconButton>
                                    </Tooltip>}

                                    <IconButton color="info" onClick={() => getInfo(api, item, close)} disabled={item?.employeesCount === 0 || false}>
                                        {infoButtonContent}
                                    </IconButton>
                                </>}

                                {/* Municipality actions */}
                                {api === "municipality" && <IconButton color="info" onClick={() => getInfo("municipality", item, close)} >
                                    {infoButtonContent}
                                </IconButton>}

                                {/* Switch button */}
                                {!!item?.boolValue && <Switch onChange={() => updateItem(item, "value", true)}
                                    color="primary" checked={item?.value === "true"} />}

                                {/* Download button */}
                                {(access && download) && <IconButton color="inherit"
                                    onClick={() => downloadFile(`${api}/download/`, item?.id)}>
                                    <Tooltip title="Ladda ner" classes={{
                                        tooltip: "tooltip-default"
                                    }} enterDelay={1000} arrow>
                                        <Download />
                                    </Tooltip>
                                </IconButton>}

                                {/* Edit button */}
                                {(access && !item?.done && !item?.boolValue && !hideButtons) &&
                                    <IconButton color="inherit"
                                        onClick={() => navigate(item?.general ? `${pathname}/edit/${item?.id}/limited` : `${pathname}/edit/${item?.id || index}`)}
                                        disabled={(api !== "logs" && (item?.general && !admin)) || !!request || !access}>
                                        <Tooltip title="Redigera"
                                            classes={{
                                                tooltip: "tooltip-default"
                                            }} enterDelay={1000} arrow>
                                            <Edit color={item?.hidden ? "error" : "inherit"} />
                                        </Tooltip>
                                    </IconButton>}

                                {/* Remove button */}
                                {(!hideButtons || download) &&
                                    <IconButton color="error" onClick={() => confirmHandle(item?.id || index)}
                                        disabled={item?.general || item?.employeesCount > 0 || (!!request && request?.id !== item?.id) || !access || "manual,data".indexOf(api) > -1}>
                                        <Tooltip title="Ta bort"
                                            classes={{
                                                tooltip: "tooltip-default"
                                            }} enterDelay={1000} arrow>
                                            {(request?.action === "delete" && request?.id === item?.id) ? <CircularProgress size={17} color="error" /> : <Delete />}
                                        </Tooltip>
                                    </IconButton>}
                            </div>
                        }

                        {/* Confirm block */}
                        {(confirm && deleteId === item?.id) && <FormButtons confirm={true}
                            value="Radera"
                            send={deleteItem}
                            clickHandle={confirmHandle} />}

                        {/* Error message */}
                        {(!!error && deleteId === item?.id) && <p className="d-row error-response" onClick={reset}><Warning color="error" /> {error} </p>}
                    </div>
                })}

                {/* If is empty list */}
                {list?.length === 0 && <p className="d-row">Här finns inget att visa</p>}
            </div>}

            {/* Pagination */}
            {(list?.length > perPage && !loading && !hidden) && <div className="fade-in pagination">
                <Pagination
                    count={Math.ceil(list?.length / perPage)}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={handlePageChange.bind(this)}
                /></div>}

            {/* Linear Loading */}
            {(loading && !hidden) && <Loading color="inherit" linear={true} height={50} />}

            {/* Modal fo api (department) */}
            {!!model && <div className="modal" onClick={() => setModel(null)}>
                <h3>{model?.item?.name}</h3>
                <p className="d-row"><AppRegistration color="primary" />
                    - &nbsp;{model?.item?.employeesCount} registrerade anställda
                </p>
                {!!model?.webCount && <p className="d-row">{(model?.webConnected > 0) ? <Web color="primary" /> : <WebAssetOff color="disabled" />}
                    - &nbsp;{model?.webConnected} person(er) anslutna via webbsidan
                </p>}
                {!!model?.item?.connectedApp && <p className="d-row">
                    <Download color={model?.item?.connectedApp === 0 ? "disabled" : "primary"} />
                    - &nbsp;{model?.item?.connectedApp} anställd(a) har laddat ner mobilapp
                </p>}
                <p className="d-row">{model?.item?.devicesCount > 1 ? <DevicesOther color="primary" />
                    : (model.item?.devicesCount === 0 ? <MobileOff color="disabled" /> : <MobileFriendly color="primary" />)}
                    - &nbsp;{model.item?.devicesCount} mobil enhet(er) registrerad
                </p>

                <Close color="error" />
            </div>}

            {/* For display child component */}
            {update ? <Outlet context={[data, refresh]} />
                : <Outlet context={[department, { api: api.split("/")[0], id: (editId || blockId), incidentId: incidentId }, data]} />}
        </div>
    </div >;
}

export default Settings;