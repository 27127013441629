import { useState } from 'react';

// Installed
import { Box, CircularProgress, LinearProgress } from '@mui/material';
import { useEffect } from 'react';


function Loading({ children, color, size = 40, linear, height }) {
    Loading.displayName = "Loading";
    
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            // setProgress((prevProgress) => (prevProgress >= 110 ? 0 : prevProgress + 10));
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 100;
                return Math.min(oldProgress + diff, 100);
            })
        }, 500)

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (linear)
        return <Box sx={{ width: '100%', marginTop: "3px" }}>
            <LinearProgress variant="determinate" color={color} style={{
                height: height,
                opacity: 0.1
            }} value={progress} />
        </Box>;

    return <div className='loading-box d-row'>
        <CircularProgress variant="indeterminate" color={color || "inherit"} size={size} />
        {!!children && children}
    </div>;
}

export default Loading;