import { useEffect, useState } from "react";

// Installed
import { IconButton } from "@mui/material";
import {
  BlurOff, BlurOn, BroadcastOnHomeOutlined, CableOutlined, Construction, ContactSupport, Home, LocationOn, Logout,
  Map, Menu, Message, PieChart, SignalCellularConnectedNoInternet0Bar, Warning
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";

// Components
import ListSelect from "./ListSelect";

// Functions
import Municipality from "../functions/Municipality";

const animatedBg = <span className="linear-bg-wrapper"><span className="linear-bg"></span></span>;

function Header({ developmentModeHandler, handleSideMenu, data, connection, connectionsMessage,
  mobileDevice, position, currentDepartment, permission, handleConnection, switchCurrentDepartment }) {
  Header.displayName = "Header";

  const navigate = useNavigate();
  const loc = useLocation();

  const [employee, setEmployee] = useState();
  const [department, setDepartment] = useState(null);
  const [development, setDevelopment] = useState(false);
  const [developmentMode, setDevelopmentMode] = useState(false);
  const [address, setAddress] = useState();
  const [viewConnectionMessage, setViewConnectionMessage] = useState(false);

  // #region useEffects
  useEffect(() => {
    if (data === false)
      navigate("/logout");
    else if (!!data) {
      setEmployee(data?.employee);
      setDepartment(currentDepartment || data?.department);
      setDevelopment(data?.developer)
      Geocode.setApiKey(data?.apiKey);
    } else
      setEmployee();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!data || !position) return;

    Geocode.fromLatLng(position?.latitude, position?.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [position, data])
  // #endregion

  // #region Functions
  const buttonClickHandle = () => {
    if (permission || mobileDevice) {
      handleSideMenu(true)
    } else {
      handleConnection();
      navigate("/logout");
    }
  }

  const handleSelectValueChange = async (value) => {
    setDepartment(value);
    switchCurrentDepartment(value);
    navigate("/");
  }

  const handleDevelopmentMode = () => {
    setDevelopmentMode(!developmentMode);
    developmentModeHandler(!developmentMode);
  }
  // #endregion

  if (data === false)
    return null;

  return <header className="d-column">
    <div className="d-row">
      <div className="logo-wrapper d-row">
        <img className="logotype" src={require(`./../assets/images/adaptive.png`)} alt='' onClick={() => navigate("/")} />
        <div className="logo-holder">
          <p onClick={() => navigate("/")}>{Municipality()}</p>

          {/* List of departments */}
          {(!employee && loc.pathname !== "/register") && animatedBg}

          {/* Connected departments */}
          {employee?.connectedDepartments?.length > 1 ?
            <ListSelect
              api={`department/notification/connections/${employee?.id}`}
              name="name"
              matchParameter="id"
              reload={loc}
              variant="standard"
              width={window.innerWidth > 414 ? "250px" : "auto"}
              selected={department?.id}
              count="activeAlarm"
              warning={true}
              handleChange={handleSelectValueChange} />
            : <p>{loc.pathname !== "/register" ? department?.name : null}</p>}
        </div>
      </div>

      {/* Menu */}
      <div className="btn-container d-row">
        {((!!department || employee) && !mobileDevice) && <>

          {development && <>
            <IconButton className={developmentMode ? "active-btn" : ""} onClick={handleDevelopmentMode}>
              <Construction />
            </IconButton>
            <IconButton className={connection ? "active-btn" : ""} onClick={handleConnection}>
              {connection ? <BlurOn /> : <BlurOff />}
            </IconButton>
          </>}

          <IconButton className={!!connection ? "active-btn" : ""} onClick={() => setViewConnectionMessage(!viewConnectionMessage)}>
            {!!connection ? <UseAnimations animation={activity} size={window.innerWidth < 485 ? 30 : 54}
              fillColor="#FFFFFF" strokeColor="#FFFFFF" /> : <SignalCellularConnectedNoInternet0Bar size={window.innerWidth < 485 ? 30 : 54} />}
          </IconButton>

          <IconButton className={(loc.pathname === "/") ? "active-link-btn" : ""} style={{ marginLeft: "30px" }} onClick={() => navigate("/")}>
            <Home />
          </IconButton>

          <IconButton className={(loc.pathname === "/warnings/notifications") ? "active-link-btn" : ""}
            onClick={() => navigate("/warnings/notifications")}>
            <Warning />
          </IconButton>

          <IconButton className={(loc.pathname === "/internal/notifications") ? "active-link-btn" : ""}
            onClick={() => navigate("/internal/notifications")}>
            <Message />
          </IconButton>

          <IconButton className={(loc.pathname === "/connections") ? "active-link-btn" : ""}
            onClick={() => navigate("/connections")}>
            <BroadcastOnHomeOutlined />
          </IconButton>

          {!!permission && <IconButton className={(loc.pathname === "/statistics") ? "active-link-btn" : ""}
            onClick={() => navigate("/statistics")}>
            <PieChart />
          </IconButton>}

          <IconButton className={(loc.pathname === "/help/manuals") ? "active-link-btn" : ""}
            onClick={() => navigate("/help/manuals")}>
            <ContactSupport />
          </IconButton>

        </>}

        {/* Get menu for support or logout for other */}
        <IconButton onClick={buttonClickHandle}>
          {(permission || mobileDevice) ? <Menu /> : <Logout />}
        </IconButton>
      </div>
      
    </div>


    <div className="position-view d-row">
      {/* View position coordinates */}
      {!viewConnectionMessage && <>
        <p className="d-row"><Map /><span>Adress:</span> {loc.pathname !== "/register" ? (!!address ? address : animatedBg) : <span className="undefined">Odefinierat</span>}</p>
        <p className="d-row"><LocationOn /><span>Lat:</span> {!!position ? position?.latitude : animatedBg},
          <span>Lng:</span> {!!position ? position?.longitude : animatedBg}</p>
      </>}

      {/* View connection information */}
      {viewConnectionMessage &&
        <p className="d-row connection-info">
          <CableOutlined />
          <span className="d-row" dangerouslySetInnerHTML={{ __html: connectionsMessage }}></span>
        </p>}
    </div>
  </header >;
}

export default Header;