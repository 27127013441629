

import { useEffect, useLayoutEffect, useState } from "react";

// Installed
import { Done, Lock, MessageOutlined, NotificationsActive, ScheduleSend, Send, SendAndArchive, SendOutlined, Warning } from "@mui/icons-material";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

// Components
import Maps from "../components/Maps";
import BoxWrapper from "../components/BoxWrapper";

// Services
import ApiRequest from "../services/ApiRequest";

function CurrentNotifications({ data, updatedView, refresh }) {

    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(null);
    const [opened, setOpened] = useState(0);

    const navigate = useNavigate();
    const { id, param, message } = useParams();
    const loc = useLocation();

    useLayoutEffect(() => {
        if (!data) return;

        setResponse(null);
        getNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loc])

    useEffect(() => {
        if (!data || !!param) return;

        getNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param])


    useEffect(() => {
        if (!updatedView) return;

        if (updatedView.id === id && message === updatedView.view)
            setOpened(updatedView.count);
        setTimeout(() => { refresh(); }, 0)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedView])

    const getNotification = async () => {

        setItem(null);
        setLoading(true);

        await ApiRequest(`notification/${message}/${id}/${data?.employee.id}`).then(res => {
            setLoading(false);
            if (res.status === 200) {
                console.log(res.data)
                setItem(res.data);
                setOpened(res.data?.view)
                refresh();
            } else
                navigate(-1);
        }, error => printError("Get current notification => " + error));
    }

    const updateNotification = async (value) => {
        const data = item;
        data.subText = value;

        await ApiRequest(`notification/${item.type}/${id}`, "put", data).then(res => {
            setResponse(res.data);
        }, error => printError(error));
    }

    const printError = (error) => {
        if (error.response.status === 401)
            navigate("/session/expired");
        setLoading(false);
        console.error(`Form error: => ${error}`)
    }

    return <BoxWrapper label={item?.incident} subLabel={item?.department}
        btn={(!!item && !item?.done && (data?.employee.id === item?.senderId || !!data?.admin)) ? { title: "Redigera avisering & status", url: "edit" } : null}
        loading={loading || !item} img={item?.type === "warning" ? <Warning /> : <MessageOutlined />}
        styles={`box-${item?.done ? "solved" : item?.type}`}>

        {(!param && !!item) && <div className="fade-in d-column notification-view">

            {/* Notification status */}
            <p className="d-row label-block">
                {item?.done && <Lock className="color-solved" fontSize="medium" />}
                {!item?.done && <NotificationsActive className={`color-${item?.type}`} fontSize="medium" />}
                <span>{item?.done ? "Stängt" : "Aktuell"}</span>
            </p>

            <Card className="message-box">
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" className="d-column message-content">
                        <p dangerouslySetInnerHTML={{ __html: item?.text?.replaceAll("\n", "<br/>") }}>
                        </p>
                        {/* Message if the warning is updated like solved */}
                        {item?.subText && <Typography className="d-row alert-sub-text">
                            <Done fontSize="medium" /> {item?.subText}</Typography>}
                    </Typography>
                </CardContent>

                <CardActions className="message-actions-wrapper d-row">
                    <Typography className="d-row message-data">
                        <span className="d-column">
                            <span className="d-row" title="Avsändare"><SendOutlined />&nbsp;{item.sender}</span>
                            <span className="d-row" title="Datum och tid"><ScheduleSend />&nbsp;{item.date}</span>
                        </span>
                        <span className="d-column">
                            <span className="d-row" title={`Mottagre`}><Send />&nbsp;{item?.receivers} anställd(a)</span>
                            <span className="d-row" title={`Levererat till ${opened} anställd(a)`}><SendAndArchive />&nbsp;{opened} anställd(a)</span>
                        </span>
                    </Typography>
                </CardActions>
            </Card>

            {!!item && <Maps coords={item?.coordinates} apiKey={data?.apiKey} />}
        </div>}

        {/* For display child component */}
        <Outlet context={[updateNotification, response]} />
    </BoxWrapper >
}

export default CurrentNotifications;