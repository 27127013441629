
// Installed
import axios from "axios";

// Functions
import { TokenConfig } from "../functions/TokenConfig";

async function ApiRequest(api, req, data) {
    try {
        if (!req && !data)
            return await axios.get(api, TokenConfig());
        else if (!data)
            return await axios[req](api, TokenConfig());

        return await axios[req](api, data, TokenConfig());
    }catch(error){
        console.log(error);
    }
}

export default ApiRequest;