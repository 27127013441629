// Installed
import { MobileOff } from "@mui/icons-material";
import { Button } from "@mui/material";

// Functions
import Municipality from "../functions/Municipality";

function MobileDisabled(props) {
    MobileDisabled.displayName = "MobileDisabled";

    const municipality = Municipality();

    const downloadApp = () => {
        window.location.href = props?.url;
    }

    return (
        <div className='container-inside mobile-disabled d-column'>
            <div className="md-wrapper d-column">
                <MobileOff size={200} color="#FFFFFF" />
                <div className="md-content d-column">
                    <p className="md-content-text"> {municipality} SOS-webapplikation är spärrad för användning på {props?.platform} mobil enheter.
                        Klicka på länken nedan att ladda ner {municipality} SOS-mobilapp på din mobil.</p>

                    <Button className="md-download-btn d-row" onClick={downloadApp}>
                        <img src={require(`./../assets/images/${props?.platform}.png`)} alt={`Ladda ner ${municipality} SOS-mobilapp`} />
                        <span className="d-column">
                            <span>Ladda ner på</span>
                            <span className="store-name">{props?.platform === "ios" ? "App Store" : "Google Play"}</span>
                        </span>
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default MobileDisabled;