
// Components
import { useEffect } from "react";
import FormUserData from "../components/FormUserData";

// Functions
import Municipality from "../functions/Municipality";

function Register({ currentDepartment, checkPermissions, navigate }) {
    Register.displayName = "Register";

    useEffect(() => {
        if (!!currentDepartment)
            navigate("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDepartment])

    return <div className='container-inside d-column'>
        <FormUserData register={true} returnDepartment={checkPermissions} title={`Var jobbar du i ${Municipality()}?`} />
    </div>;
}

export default Register;