
import { useEffect, useState } from "react";

// Installed
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { PhoneDisabled } from "@mui/icons-material";

// Services
import ApiRequest from "../services/ApiRequest";
import { useNavigate } from "react-router-dom";

function ListSelect({ api, disabled, name, selected, readyList, loading, reload,
    multiple, variant, label, width, sort, param, required, matchParameter, count, warning,
    disabledItem, handleChange }) {

    const [list, setList] = useState(readyList || null);
    const [renderValue, setRenderValue] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!api || disabled || !!list) return;
        getListByApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        if (!multiple && list?.length > 0) {
            const value = list?.find(x => (!!readyList ? x : x.id) === selected);
            setRenderValue(!!readyList ? value : value?.name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, selected, multiple, readyList])

    const getListByApi = async () => {
        await ApiRequest(api).then(res => {
            setList(res.data);
        }, error => {
            if (error.response.status === 401)
                navigate("/session/expired");
            console.error(`Get list ${api} => ${error}`);
        });
    }

    const onChange = (e) => {
        const value = e.target.value;
        if (!!matchParameter && !multiple)
            handleChange(list.find(x => x[matchParameter] === value));
        else
            handleChange(value);
    }

    if (disabled && !readyList)
        return null;
    else if (disabled)
        return <p className="d-row disabled-message"><PhoneDisabled /> {label}</p>;

    return <FormControl className="select-wrapper" style={{ width: !!width ? width : "300px" }}>
        {!!label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}

        <Select
            onChange={onChange}
            label={label}
            sx={{
                height: 50
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="select-list"
            multiple={multiple}
            variant={!!variant ? variant : "outlined"}
            displayEmpty
            fullWidth
            required={!!required}
            disabled={!list || loading}
            renderValue={(value) => !!multiple ? value?.join(', ') : renderValue}
            defaultValue={!!list ? selected : (!!multiple ? [] : "")}
            value={!!list ? selected : (!!multiple ? [] : "")}
            MenuProps={!!multiple ? {
                PaperProps: {
                    style: {
                        maxHeight: 450,
                        width: 250
                    }
                }
            } : {}}
        >

            {!readyList && <MenuItem disabled value="">
                <ListItemText primary="Välj från listan nedan .." />
            </MenuItem>}

            {/* If the list is fetched from api request */}
            {(!!api && !readyList && !!list) && (!!sort ? list.filter(x => sort.some(s => s === x[param])) : list)?.map((item, index) => (
                <MenuItem key={index}
                    value={!!matchParameter ? item[matchParameter] : item}
                    style={{ whiteSpace: "unset" }}
                    disabled={!!disabledItem && disabledItem === item[name]}>

                    {/* For only one choice */}
                    {!multiple && <p className="d-row menu-item">
                        <span><span>•</span><span>{item[name]} </span></span>
                        {(!!count && item[count] > 0) && <span className={!warning ? "count-inactive" : "count-active"}>{item[count]}</span>}
                    </p>}

                    {/* If multiple choice is possible  */}
                    {multiple && <>
                        <Checkbox checked={selected?.indexOf(item[name]) > -1} />
                        <ListItemText primary={item[name]} />
                    </>}
                </MenuItem>
            ))}

            {/* If the list is easy list and send from parent component */}
            {!api && list?.map((item, index) => (
                <MenuItem value={item} key={index}>
                    <span style={{ marginLeft: "10px" }}> • {item}</span>
                </MenuItem>
            ))}
        </Select>

    </FormControl>
}

export default ListSelect;