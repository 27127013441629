
import { useEffect, useRef, useState } from "react";

// Installed
import { ArrowDropDown, ArrowDropUp, ChevronRightOutlined, Close, Logout } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// Functions
import Municipality from "../functions/Municipality";

const links = [
    { label: "Incidentkategorier", link: "settings/incidents", permission: false },
    { label: "Arbetsplatser", link: "settings/departments", permission: false },
    { label: "Användare", link: "settings/employees", permission: false },
    { label: "Blockerade användare", link: "settings/blocked", permission: false },
    { label: "Varningar", link: "settings/notifications/warnings", permission: false },
    { label: "Intern meddelande", link: "settings/notifications/internal", permission: false },
    { label: "Kommuner", link: "settings/municipality", permission: true },
    { label: "Inställningar", link: "settings/data", permission: true },
    {
        label: "Kontrollpanel", development: true, links: [
            { label: "Loggfiler", link: "settings/logs" },
            { label: "Instruktioner", link: "settings/manuals" },
            { label: "Konfiguration", link: "settings/configuration" },
            { label: "Skicka instruktion", link: "settings/send/instruction" },
            { label: "Skicka meddelande", link: "settings/send/notification" }
        ]
    }
];

const mobileLinks = [
    { label: "Hem", link: "" },
    { label: "Anslutningar", link: "connections" }
]

function SideMenu({ data, open, close, mobileDevice, closeConnection }) {
    const [visibleClass, setVisibleClass] = useState("");
    const [visibleMenu, setVisibleMenu] = useState(false);
    const [dropdown, setDropdown] = useState(null);

    const navigate = useNavigate();
    const loc = useLocation();
    const refMenu = useRef();
    const refButton = useRef();

    useEffect(() => {
        let clickHandler = (event) => {
            if (refMenu.current && !refMenu.current?.contains(event.target) && visibleMenu) {
                setVisibleClass("");
                setTimeout(() => {
                    setVisibleMenu(false);
                    close(false);
                }, 750)
            }
        }

        document.addEventListener("mousedown", clickHandler);
        return () => {
            document.removeEventListener("mousedown", clickHandler);
        }
    })

    useEffect(() => {
        setVisibleMenu(true);
        setTimeout(() => {
            setVisibleClass(" side-menu-visible");
        }, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleClick = (link, index = null) => {
        if (index == null) {
            closeMenu();
            navigate(link);
        } else {
            refButton.current?.scrollIntoView({ behavior: "smooth" });
            setDropdown(dropdown === index ? null : index);
        }

    }

    const closeMenu = () => {
        setVisibleClass("");
        setTimeout(() => {
            setVisibleMenu(false);
            close(false);
        }, 750)
    }

    return (
        <div className="side-menu-container">
            <div className={"side-menu" + visibleClass} ref={refMenu}>
                <div className="logo-wrapper d-row">
                    <div className="logo-holder d-column" style={{ alignItems: "flex-start" }}>
                        <p>{Municipality()}</p>
                        {!data?.admin && <p>{data?.department?.name}</p>}
                    </div>
                    <IconButton onClick={closeMenu} color="inherit" size="large">
                        <Close />
                    </IconButton>
                </div>
                <div className="sm-wrapper d-column">
                    <div className="sm-links-wrapper">
                        {(mobileDevice ? mobileLinks : links).map((l, index) => {
                            if (l?.permission && !data?.admin)
                                return null;
                            else if (l?.development && !data?.developer)
                                return null;

                            return <div key={index} className={"d-column sm-link" + (loc.pathname.indexOf(l.link) > -1 ? " active" : "") + (dropdown === index ? " dropdown" : "")}>
                                <p className="d-row" onClick={() => handleClick("/" + l.link, !!l.links ? index : null)}>
                                    <span className="d-row"><ChevronRightOutlined /> {l.label}</span>
                                    {!!l.links && (dropdown === index ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />)} </p>

                                {/* Sub links */}
                                {!!l.links && <p className={`sm-sub-links-wrapper dropdown-${(dropdown === index ? "visible" : "hidden")}`}>
                                    {l.links.map((sl, ind) => {
                                        return <span key={ind} className="d-row sm-sub-link" onClick={() => handleClick("/" + sl.link)}>
                                            • {sl.label}
                                        </span>
                                    })}
                                </p>}
                            </div>
                        })}
                    </div>

                    <Button onClick={() => {
                        closeConnection();
                        handleClick("/logout");
                    }} variant="outlined" color="error" ref={refButton}>
                        <Logout />&nbsp; Logga ut
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SideMenu;