
import { useNavigate } from "react-router-dom";

// Installed
import { IconButton, Tooltip } from "@mui/material";
import { Edit, KeyboardReturn } from "@mui/icons-material";

// Components
import Loading from "./Loading";

function BoxWrapper({ children, label, subLabel, btn, additionalButtons, loading, disabled, styles, img }) {

    const navigate = useNavigate();
    const animatedBg = <span className="linear-bg-wrapper"><span className="linear-bg"></span></span>;
    return <div className="container-inside">
        <div className="box-wrapper">
            {/* Heading block */}
            <div className={`box-heading d-row ${styles}`}>

                {/* Label */}
                <div className="box-header-wrapper">
                    <h3 className="d-row">
                        {!!label && img} 
                        {!label ? animatedBg : label}
                    </h3>
                    {!!subLabel && <p className="d-row">{subLabel}</p>}
                </div>

                {/* Button wrapper */}
                <div className="d-row box-btn-container" style={{ width: "max-content" }}>

                    {/* Extra button */}
                    {(!loading && additionalButtons && !disabled) && additionalButtons}

                    {/* Navigate to specific page */}
                    {!!btn && <Tooltip title={btn?.title}
                        classes={{
                            tooltip: "tooltip-red",
                            arrow: "tooltip-arrow-red"
                        }}
                        disableTouchListener={disabled} enterDelay={1000} arrow>
                        <span>
                            <IconButton onClick={() => navigate(btn?.url)} disabled={disabled || loading}>
                                <Edit />
                            </IconButton>
                        </span>
                    </Tooltip>}

                    {/* Return button */}
                    <Tooltip title="Gå ett steg bakåt"
                        classes={{
                            tooltip: "tooltip-red",
                            arrow: "tooltip-arrow-red"
                        }} enterDelay={1000} arrow>
                        <IconButton onClick={() => navigate(-1)} >
                            <KeyboardReturn />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {/* Children content */}
            {loading ? <Loading color="error" /> : children}
        </div>
    </div>
}

export default BoxWrapper;