// Components
import Modal from './Modal';

function ExpiredSession({navigate}) {
    ExpiredSession.displayName = "ExpiredSession";

    return (
        <Modal open={true} msg="Sessionen har löpt ut" 
            content="Var vänlig logga in igen." close={() => navigate("/logout")}/>
    )
}

export default ExpiredSession;