export async function Fetch(api) {
    const response = await fetch(api, {
        "headers": {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*'
        }
    });

    if (response.status === 200)
        return await response.json();

    return null;
}