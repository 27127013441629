import { useEffect } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";


function FormPage() {

    const defaultFormData = { clientSecretId: "" };

    const [data, refresh] = useOutletContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!data?.admin)
            navigate(-1);
    }, [data, navigate])

    return <Outlet context={[defaultFormData, {api: "data/updateKeys", inputs: [{name: "clientSecretId", label: "ClientSecret"}]}, data, refresh]} />
}

export default FormPage;