import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import Loading from "../components/Loading";
import Container from "../components/Container";
import Manuals from "../components/Manuals";

// Service
import ApiRequest from "../services/ApiRequest";

function SupportManual() {
    SupportManual.displayName = "SupportManual";

    const [manual, setManual] = useState();
    const [loading, setLoading] = useState(false)

    const { id } = useParams();

    useEffect(() => {
        (async () => {
            await ApiRequest(`manual/by/link/developer/${id}`).then(res => {
                if (res != null)
                    setManual(res.data);
            }, (error) => {
                console.log(error);
            })
            setLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) return <Loading />;

    return (
        <Container label="Kommun SOS-app" subLabel="Start guide för developer" width="1000px">
            <Manuals manuals={[manual]} color="#000000" />
        </Container>
    )
}

export default SupportManual;