import { Warning } from '@mui/icons-material';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import signalWarning from './../assets/sounds/warning.mp3';

function WarningNotice({ warning, reset }) {
    const signal = new Audio(signalWarning);
    const refWarning = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        let clickHandler = (event) => {
            if (refWarning.current && !refWarning.current?.contains(event.target))
                close();
        }

        document.addEventListener("mousedown", clickHandler);
        return () => {
            document.removeEventListener("mousedown", clickHandler);
        }
    })

    useEffect(() => {
        signal.play();

        setTimeout(() => {
            signal.pause();
        }, 6000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const close = () => {
        signal.pause();
        reset();
    }

    return <div className="warnings-container d-column">
        <div className="warning-wrapper d-column" ref={refWarning} onClick={() => navigate("view/notification/" + warning?.id)}>
            <Warning />
            <div className='d-column' dangerouslySetInnerHTML={{ __html: warning.message }}></div>
        </div>
    </div>
}

export default WarningNotice;