import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Popup({ model, reset }) {

    
    const navigate = useNavigate();

    useEffect(() => {
        if (!model?.sound)
            return;
        const signal = new Audio(require(`./../assets/sounds/${model?.sound}.wav`));
        signal.play();

        setTimeout(() => {
            signal.pause();
        }, 5000)
    }, [model])

    function handleClick() {
        if (!!model?.id)
            navigate("view/notification/" + model?.id);

        reset();
    }

    return (
        <div className="popup-container d-column" onClick={handleClick}>
            <div className={`popup-content ${model?.class}`}>
                {model?.img}
                <div dangerouslySetInnerHTML={{ __html: model?.message }}></div>
            </div>
        </div>
    )
}

export default Popup;
