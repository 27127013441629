function Container({ children, label, subLabel, width = "inherit" }) {
    return (
        <div className="container-inside" style={{width: width}}>
            <div className="box d-column">
                {/* Panel */}
                <div className="box-menu d-row">
                    {/* Heading */}
                    <div className="box-header-wrapper">
                        <h3 className="d-row">{label}</h3>
                        <p>{subLabel}</p>
                    </div>
                </div>

                {children}
            </div>
        </div>
    )
}

export default Container;