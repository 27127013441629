
import { useState, useEffect } from 'react';

// Services
import ApiRequest from '../services/ApiRequest';

function Municipality() {
  const [municipality, setMunicipality] = useState(null);
 
  let stored = JSON.parse(localStorage.getItem("municipality")); 

  useEffect(() => {
    if (!stored)
      getMunicipalityData()
    else {
      document.title = stored?.name + " SOS-app";
      setMunicipality(stored?.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMunicipalityData = async () => {
    const res = await ApiRequest("municipality/check/important/data");
    if (!!res?.data){  
      setMunicipality(res.data.name);
      document.title = res.data.name + " SOS-app";
      localStorage.setItem("municipality", JSON.stringify(res?.data));
      window.location.reload();
    }
  }

  return municipality;
}

export default Municipality;