// Installed
import { Close, KeyboardReturn, Repeat } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Response({ msg, success, error, reset }) {
    const msgTrue = !!success || (typeof msg === 'boolean' && !!msg) || msg === undefined || msg?.length === 0;
    const response = error ? "error" : (msgTrue ? "success" : "warning");

    const navigate = useNavigate();

    return <div className={`response-box ${response} d-column`}>
        <Alert color={response} variant="outlined" severity={response}>
            <p className="response-message" dangerouslySetInnerHTML={{ __html: msgTrue ? "Skickad!" : msg }}></p>
            {(!!error && typeof error !== 'boolean') && 
                <span className="error-message" dangerouslySetInnerHTML={{ __html: error }}></span>}
        </Alert>
        <div className="d-row response-buttons">
            {!!reset && <Button variant="outlined" color="inherit" onClick={() => reset(!error)}>
                {!error ? <Repeat /> : <KeyboardReturn  />}
            </Button>}
            <Button variant="outlined" color="inherit" style={{marginLeft: "10px"}} onClick={() => navigate(-1)}>
                <Close  />
            </Button>
        </div>
    </div>
}

export default Response;