
import { useEffect, useState } from 'react';

// Installed
import { Alert, AlertTitle, Button, CircularProgress } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { useLocation } from 'react-router-dom';

// Functions
import Municipally from '../functions/Municipality';

// Services
import ApiRequest from '../services/ApiRequest';

// Json
import message from "../assets/json/message.json";


function Start({ login }) {
  Start.displayName = "Start";

  const { instance } = useMsal();
  const loc = useLocation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loc.pathname !== "/")
      window.location.href = "/";

    async function removeSavedToken(){
      await ApiRequest("data/remove/session");
    }

    removeSavedToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!login) handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])

  const handleLogin = () => {
    setLoading(true);
    const loginRequest = {
      scopes: [JSON.parse(localStorage.getItem("municipality"))?.scope]
    }
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <div className='d-row start-wrapper'>
      <div className="start-logotype"></div>
      <p className='start-logo-text'>{Municipally()}</p>
      <Button
        id="login"
        className="login-button"
        disabled={loading}
        onClick={handleLogin}>
        {loading ? <CircularProgress color="inherit" size={25} /> : "Logga in"}
      </Button>

      {message !== null && <Alert className='message-container' severity={message?.color} action>
          <AlertTitle>Information</AlertTitle>
          <p className='message-wrapper' dangerouslySetInnerHTML={{__html: message?.content}}></p>
        </Alert>}
    </div>
  );
}

export default Start;