import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';

// Installed
import { Done, NotificationsOff, Message } from '@mui/icons-material';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

// Components
import Header from '../components/Header'
import FormNotification from '../components/FormNotification';
import FormUserData from '../components/FormUserData';
import SideMenu from '../components/SideMenu';
import FormPage from '../components/FormPage';
import Form from '../components/Form';
import NotificationsList from '../components/NotificationsList';
import WarningNotice from '../components/WarningNotice';
import Popup from '../components/Popup';
import Loading from '../components/Loading';

// Pages
import Register from '../pages/Register';
import Home from '../pages/Home';
import Messages from '../pages/Messages';
import Logout from '../pages/Logout';
import Settings from '../pages/Settings';
import SendNotificationMail from '../pages/SendNotificationMail';
import Statistics from '../pages/Statistics';
import CurrentNotifications from '../pages/CurrentNotifications';
import Connections from '../pages/Connections';
import Configuration from '../pages/Configuration';
import Notifications from '../pages/Notifications';
import UserManual from '../pages/UserManual';

// Functions
import { DecodedToken } from '../functions/DecodeToken';
import BrowserNotification from '../functions/BrowserNotification';
import isLocalhost from '../functions/isLocalhost';

// Services
import ApiRequest from '../services/ApiRequest';

function AuthorizedRoutes({ isMobileDevice, mobileType, isAuthorized, isRegistered }) {
    AuthorizedRoutes.displayName = "AuthorizedRoutes";

    const current = sessionStorage.getItem("currentDepartment");
    const [currentDepartment, setCurrentDepartment] = useState((!!current && current !== "undefined") ? JSON.parse(current) : null);
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState(null);
    const [connection, setConnection] = useState(null);
    const [connected, setConnected] = useState([]);
    const [warning, setWarning] = useState();
    const [data, setData] = useState();
    const [newNotification, setNewNotification] = useState();
    const [updatedNotification, setUpdatedNotification] = useState(null);
    const [popup, setPopup] = useState();
    const [connectionsMessage, setConnectionsMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [permission, setPermission] = useState(false);

    const municipality = JSON.parse(localStorage.getItem("municipality"))?.name;
    const navigate = useNavigate();

    useEffect(() => {
        
        if (localStorage.getItem("token") && !localStorage.getItem("ad-token")){
            localStorage.removeItem("token");
            window.location.href = "/logout";
        }

        // Set current location coordinates
        navigator.geolocation.getCurrentPosition(position => {
            setPosition({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
        })

        // Permission for window notification
        if (!("Notification" in window)) {
            if (!mobileType?.phone() && !mobileType.tablet()) {
                setPopup({
                    img: <NotificationsOff />,
                    message: "<p>Push-meddelande på din webbläsare är inaktivt!</p>",
                    class: "warning"
                });
            }
        } else
            Notification.requestPermission();

        setTimeout(() => {
            checkPermissions();
            joinDepartment();
        }, 100)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        sessionStorage.setItem("currentDepartment", JSON.stringify(currentDepartment));
    }, [currentDepartment])

    useEffect(() => {
        if (!data && !!connection) return;
        joinDepartment();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const checkPermissions = async () => {

        if (!isRegistered) {
            unknownUser();
            return;
        }

        var email = DecodedToken()?.upn;
        await ApiRequest("data/check/permission/by/" + email).then(response => {
            const res = response?.data;

            if (!res) {
                unknownUser()
                return;
            } else {
                if (!current) setCurrentDepartment(res?.department);

                localStorage.setItem("employeeEmail", email);

                var user = res.employee;
                setData({
                    employee: user,
                    admin: user.admin,
                    developer: user.developer,
                    department: res?.department,
                    currentNotifications: res.currentNotifications,
                    apiKey: res?.apiKey,
                    developmentMode: false
                });
            }

            setPermission((res?.employee?.support && res?.employee?.department === res.department?.name) || res?.employee?.admin);
            setLoading(false);
        }, error => {
            if (error.response.status === 401)
                navigate("/session/expired");
            console.error("Check permission error => " + error);
            setLoading(false);
        });
    }

    const unknownUser = () => {
        localStorage.removeItem("employeeEmail");
        setCurrentDepartment(null);
        setLoading(false);
        navigate("/register");
    }

    const joinDepartment = async () => {
        if (isLocalhost) return;

        const url = "https://" + window.location.host + "/notifications";
        const email = !!DecodedToken() ? DecodedToken()?.upn : localStorage.getItem("employeeEmail");

        if (!email) return;

        try {
            const connection = new HubConnectionBuilder()
                .withUrl(url)
                .configureLogging(LogLevel.Information)
                .build();

            connection.on("ConnectionMessage", (res, message) => {
                if (email === res?.email)
                    setConnectionsMessage(message);
                (async () => {
                    await connection.invoke("UpdateConnections");
                })()
            });

            connection?.on("ConnectedEmployees", connections => {
                setConnected(connections);
            });

            connection.on("NotificationMessage", (res, message) => {
                handleReset();

                if (email !== res?.email) {
                    if (document.hidden || document.visibilityState === "hidden") {
                        BrowserNotification({
                            content: message,
                            img: "adaptive"
                        });
                    }

                    if (!!res?.done || !!res?.internal) {
                        setPopup({
                            id: res?.notificationUrl,
                            img: !!res.done ? <Done /> : <Message />,
                            message: message,
                            sound: !!res.done ? "solved" : "internal",
                            class: !!res.done ? "solved" : "internal",
                        });
                    } else {
                        setWarning({
                            id: res?.notificationId,
                            message: message
                        })
                    }
                }
                setNewNotification(true);
            });

            connection.on("UpdateNotificationView", (view, id, count) => {
                setUpdatedNotification({
                    view: view,
                    id: id,
                    count: count
                });
            });

            connection.onclose(e => {
                setConnection();
            })

            await connection.start();
            await connection.invoke("JoinDepartment", { email: email, developer: data?.developmentMode });
            setConnection(connection);
        } catch (error) {
            console.error("Join connection => " + error);
        }
    }

    const updateCurrentDepartment = (value) => {
        setCurrentDepartment(value);
        setData({ ...data, department: value })
        setPermission((data?.employee?.support && data?.employee?.department === value?.name) || data?.admin);
    }

    const setDevelopmentMode = (value) => {
        setData({ ...data, developmentMode: value });
        connectionClose();
        joinDepartment();
    }

    const handleConnection = async () => {
        if (!!connection)
            await connectionClose();
        else
            await joinDepartment();
    }

    const handleReset = () => {
        setPopup();
        setWarning();
        setNewNotification(false);
    }

    const connectionClose = async () => {

        if (!connection) return;

        try {
            await connection.stop();
        } catch (error) {
            console.error("Connection close error => " + error);
        }
    }

    return (
        <>
            {/* Header */}
            <Header data={data} mobileDevice={isMobileDevice} connection={connection} connectionsMessage={connectionsMessage}
                currentDepartment={currentDepartment} position={position} permission={permission} handleConnection={handleConnection}
                switchCurrentDepartment={updateCurrentDepartment}
                developmentModeHandler={setDevelopmentMode} handleSideMenu={setOpen} />

            {/* Loading */}
            {loading && <Loading>
                {!currentDepartment && <p className="loading-content">Var god vänta, dina uppgifter kontrolleras ...</p>}
            </Loading>}

            {/* Routes */}
            {!loading && <Routes>
                <Route path="/" element={<Home currentDepartment={currentDepartment} />} />
                <Route path="/register" element={<Register checkPermissions={(value) => {
                    setCurrentDepartment(value);
                    checkPermissions();
                }} currentDepartment={currentDepartment} navigate={(url) => navigate(url)} />} />

                {/* Routes or already registered users */}
                {!!currentDepartment && <>
                    <Route path="/:keyword/incident/:id" element={<Messages connection={connection}
                        currentDepartment={currentDepartment} position={position} developmentMode={!!data?.developmentMode} />} >
                        <Route path=":param" element={<FormNotification title="Skicka eget meddelande" />} />
                    </Route>

                    <Route path="/connections" element={<Connections department={currentDepartment} connection={connection} connected={connected} />} />

                    <Route path="view/notification/:message/:id" element={<CurrentNotifications data={data} updatedView={updatedNotification} refresh={handleReset} />}>
                        <Route path=":param" element={<FormNotification title="Updatera status" />} />
                    </Route>
                </>}

                <Route path="/:list/notifications" element={<Notifications department={currentDepartment} employee={data?.employee} />} />

                {/* Routes for admin and support users */}
                {permission && <>
                    <Route path="/statistics" element={<Statistics />} />

                    <Route path="/settings/incidents" element={<Settings api="incident" accessible={true} visible={true} label="Incidentkategorier" link="messages" data={data} />} >
                        <Route path=":action" element={<Form title="Ny incident kategori" model="incident" />} />
                        <Route path="edit/:editId" element={<Form title="Redigera incident category" model="incident" />} />
                        <Route path="edit/:editId/limited" element={<Form title="Redigera incident category" activated="phoneNumbers" model="incident" />} />
                    </Route>

                    <Route path="/settings/incidents/messages/:incidentId" element={<Settings label="Varning texter" api="incident" data={data} visible={true} />} >
                        <Route path=":action" element={<Form title="Ny varning text" model="message" />} />
                        <Route path="edit/:editId" element={<Form title="Redigera text" model="message" />} />
                    </Route>

                    <Route path="/settings/departments" element={<Settings api="department"
                        label="Arbetsplatser" connection={connection} data={data} mail={true} visible={data?.admin} />}>
                        <Route path=":action" element={<Form title="Ny arbetsplats" model="department" />} />
                        <Route path="edit/:editId" element={<Form title="Redigera arbetsplats" model="department" />} />
                    </Route>

                    <Route path="/settings/employees" element={<Settings api="employee" label="Användare" count="employeesCount" link="view"
                        data={data} accessible={data?.employee?.support} visible={data?.admin} search={true} mail={true} />}>
                        <Route path="view/:viewId" element={<FormUserData title="Användaruppgifter" />} />
                        {data?.admin && <Route path=":action" element={<FormUserData title="Ny användare" permission={true} />} />}

                        {(data.admin || data?.employee?.support) && <>
                            <Route path="edit/:editId" element={<FormUserData title="Redigera användare" permission={data?.admin} employee={data?.employee} />} />
                            <Route path="block/:blockId" element={<Form title="Blockera användare" model="block" />} />
                        </>}
                    </Route>
                    <Route path="/settings/employees/sort/:paramId" element={<Settings api="employee" label="Användare"
                        count="employeesCount" link="/settings/employees/view" search={true} data={data} mail={true} visible={data?.admin} />} />
                    <Route path="/settings/employees/search/:searchId"
                        element={<Settings api="employee" label="Användare" subText={`Sökresultat (${municipality})`}
                            count="employeesCount" link="/settings/employees/view" search={true} data={data} mail={true} visible={data?.admin} />} />

                    <Route path="/settings/blocked" element={<Settings api="blocked" label="Blockerade användare" count="blockedCount" data={data} visible={data?.admin} />}>
                        {data?.admin && <Route path=":action" element={<Form title="Ny blockering" model="block" />} />}
                        <Route path="edit/:editId" element={<Form title="Redigera blockerad användare" model="block" />} />
                    </Route>

                    <Route path="/settings/notifications/:param" element={<Settings api="notification" data={data} visible={false}
                        count="activeAlarm" accessible={data?.admin || data?.employee?.support} link="/view/notification" />}>
                        <Route path="edit/:editId" element={<Form title="Uppdatera status" model="notification" />} />
                    </Route>
                    <Route path="/settings/notifications/:param/sort/:paramId" element={
                        <Settings label="Varningar" count="activeAlarm" link="/view/notification" data={data} visible={false} />} />

                    <Route path="/settings/municipality" element={<Settings api="municipality" label="Kommuner" subText="Kommun SOS-app brukare"
                        data={data} hideButtons={!data?.developer} visible={data?.developer} />}>
                        {data?.developer && <Route path=":action" element={<Form title="Ny kommun" model="municipality" />} />}
                        {data?.developer && <Route path="edit/:editId" element={<Form title="Redigera kommun" model="municipality" />} />}
                    </Route>

                    <Route path="/settings/data" element={<Settings api="data" label="Inställningar"
                        subText={municipality} data={data} visible={data?.developer} />}>
                        {data?.developer && <Route path=":action" element={<Form title="Ny inställning" model="data" />} />}
                        <Route path="edit/:editId" element={<Form title="Redigera inställning" model="data" />} />
                        <Route path="update/:update" element={<FormPage />} >
                            <Route path="" element={<Form title="Uppdatera hemliga nycklar" model="data" />} />
                        </Route>
                    </Route>
                </>}

                {/* Developer mode */}
                {data?.developer && <>
                    <Route path="/settings/logs" element={<Settings api="logs" label="Loggfiler" hideButtons={true} data={data} download={true} />} />
                    <Route path="/settings/manuals" element={<Settings api="manual" label="Instruktioner" visible={data?.developer} data={data} />}>
                        <Route path=":action" element={<Form title="Ny manual" model="manual" />} />
                        <Route path="edit/:editId" element={<Form title="Redigera manual" model="manual" />} />
                    </Route>
                    <Route path="/settings/configuration" element={<Configuration />} />
                    <Route path="/settings/configuration/edit" element={<Configuration edit={true} />} />
                    <Route path="/settings/send/notification" element={<SendNotificationMail model="notice" label="meddelande" api="notification/push/notice" />} />
                    <Route path="/settings/send/instruction" element={<SendNotificationMail model="mail" label="instruktion till utvecklare" api="manual/send/link" />} />
                </>}

                {/* Manual */}
                <Route path="/help/manuals" element={<UserManual data={data} />} />

                {/* Logout */}
                <Route path="/logout" element={<Logout isAuthorized={isAuthorized} />} />
                <Route path="/session/expired" element={<Logout isAuthorized={isAuthorized} expired={true} />} />

                {/* If route is no exists */}
                <Route path="/*" element={<Home currentDepartment={currentDepartment} />} />
            </Routes>}


            {/* Current notifications list */}
            <NotificationsList notification={newNotification} authorized={true} email={data?.employee?.email}
                popup={!!warning || !!popup} reset={() => setNewNotification(false)} />

            {/* Warning window */}
            {!!warning && <WarningNotice warning={warning} reset={handleReset} />}

            {/* BrowserNotification's popup */}
            {popup && <Popup model={popup} reset={handleReset} />}

            {/* Side menu */}
            {(open && (isMobileDevice || data?.employee.support)) &&
                <SideMenu open={open} data={data} mobileDevice={isMobileDevice} closeConnection={connectionClose} close={setOpen} />}
        </>
    )
}

export default AuthorizedRoutes;