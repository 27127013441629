
import React, { useState } from 'react';

// Installed
import { Close, PictureAsPdf, VideoFile } from '@mui/icons-material';
import { Button, List, ListItem, ListItemText } from '@mui/material';
import ReactPlayer from 'react-player';

function Manuals({ manuals, color = "inherit" }) {
    Manuals.displayName = "Manuals";
    
    const [video, setVideo] = useState(null);

    const navigate = (link) => {
        window.open(`https://sos-app.alvesta.se/manuals/${link}`);
    }

    if(!manuals || manuals?.length === 0) return null;

    return (
        manuals?.map((item, index) => {
            return <List className="fade-in list-li-wrapper" key={index}>
                <ListItem>
                    <ListItemText primary={item?.name} color="#cc0000" />
                </ListItem>

                {/* Manuals content */}
                <ListItem className="d-column list-li-names" style={{color: color}}>
                    {/* Pdf */}
                    {!!item?.docLink &&
                        <Button color="inherit" onClick={() => navigate(item?.docLink)} startIcon={<PictureAsPdf />}> Öppna pdf</Button>}

                    {/* Videos */}
                    {item?.videos?.length > 0 && <div className="d-row list-div-buttons">
                        {item?.videos?.map((url, ind) => {
                            return <Button
                                key={ind}
                                color={video === url ? "error" : "inherit"}
                                onClick={() => setVideo(video !== url ? url : null)}
                                startIcon={video === url ? <Close /> : <VideoFile />} >
                                Video {ind > 8 ? ind + 1 : (`0${ind + 1}`)}
                            </Button>;
                        })}
                    </div>}

                    {/* Empty */}
                    {item?.isEmpty && <p>Ingen manual hittades</p>}
                </ListItem>

                {/* Video player */}
                {(!!video && item?.videos?.indexOf(video) > -1) && <ListItem className="list-li-block">
                    <ReactPlayer
                        url={`https://sos-app.alvesta.se/manuals/${video}`}
                        controls={true}
                        light={true}
                        pip={true}
                        stopOnUnmount={false} />
                </ListItem>
                }
            </List>
        })
    )
}

export default Manuals;