import { useEffect, useState } from "react";

// Installed
import { MapsHomeWork, Message, Place, SpeakerNotesOff, Warning } from "@mui/icons-material";
import { Alert, Button, IconButton, TextField, Tooltip } from "@mui/material";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import UseAnimations from 'react-useanimations';
import alertCircle from 'react-useanimations/lib/alertCircle';

// Components
import FormButtons from "../components/FormButtons";
import Response from "../components/Response";
import BoxWrapper from "../components/BoxWrapper";
// import ListSelect from "../components/ListSelect";

// Functions
import { DecodedToken } from "../functions/DecodeToken";

// Services
import ApiRequest from "../services/ApiRequest";

function Messages({ position, currentDepartment, developmentMode }) {
    Messages.displayName = "Messages";

    const { id, param } = useParams();
    const loc = useLocation();
    const navigate = useNavigate();

    const [messages, setMessages] = useState(null);
    const [incident, setIncident] = useState();
    const [confirmId, setConfirmId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [receivers, setReceivers] = useState([]);
    const [response, setResponse] = useState(null);
    const [notification, setNotification] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(true);
    const [alertStatus, setAlertStatus] = useState(true);
    const [sendSms, setSendSms] = useState(false);

    const info = "Intern meddelande är endast avsedd att skicka meddelanden till anställda inom respektive arbetsplats, d.v.s." +
        "larmet skickas inte någonstans och aviseringstonen kan låta annorlunda.";

    useEffect(() => {
        setTimeout(() => {
            (async () => {
                await ApiRequest(`incident/${id}`).then(res => {
                    const incident = res.data;
                    setLoading(false);
                    setMessages(incident.messages);
                    setIncident(incident);
                    setSendSms(incident?.highRisk);
                    setCurrentLocation(!incident.internal);
                })
                setReceivers([DecodedToken().upn])
            })()

        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        reset();
    }, [loc])

    useEffect(() => {
        if (!alertStatus) return;
        setTimeout(() => {
            setAlertStatus(!alertStatus)
        }, 3000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertStatus])

    const confirmHandle = (msg, index) => {
        setResponse(null);
        setConfirmId(index);
        setNotification(msg);
    }

    const inputChangeHandler = (e) => {
        setReceivers(e.target.value.split(","))
    }

    const sendNotification = async (value) => {
        if (!!notification) {
            value = notification;
            setLoading(true);
        }

        // Notification content
        const data = {
            incident: incident.name,
            text: value,
            sender: DecodedToken().upn,
            departmentId: currentDepartment?.id,
            latitude: currentLocation ? position?.latitude : currentDepartment.latitude,
            longitude: currentLocation ? position?.longitude : currentDepartment.longitude,
            testReceivers: developmentMode ? receivers : [],
            sendSms: sendSms && incident?.highRisk && !developmentMode,
            isWarning: !incident.internal
        };

        await ApiRequest("notification", "post", data)
            .then(res => {
                setLoading(false);
                if (!!res.data)
                    setResponse(res.data);
                else
                    printError(res.data)
            }, error => printError(error))
    }

    const reset = () => {
        setResponse(null);
        setConfirmId(null);
        setNotification();
    }

    const printError = (error) => {
        if (error.response.status === 401)
            navigate("/session/expired");
        reset();
        setLoading(false);
        setResponse({ message: error, error: true });
        console.error(`Push notification error: => ${error}`)
    }

    return <BoxWrapper
        label={incident?.name}
        loading={!messages || (loading && !confirmId)}
        btn={{ title: "Eget meddelande", url: "write" }}
        img={<Warning />}
        styles={`color-${incident?.messageType}`}
        additionalButtons={
            <>
                {/* Info button */}
                {!!incident?.internal && <Tooltip title={info}
                    classes={{
                        tooltip: "tooltip-red",
                        arrow: `tooltip-arrow-red`
                    }} enterDelay={1000} arrow>
                    <IconButton style={{ padding: 0 }}>
                        <UseAnimations animation={alertCircle} size={40} fillColor="#FFFFFF" strokeColor="#FFFFFF" />
                    </IconButton>
                </Tooltip>}

                {incident?.highRisk && <Tooltip title={`${sendSms ? "Inaktivera" : "Aktivera"} sms-sändning.\n\rObs! Extra avgift tillkommer på 0.50 - 0.99 kr per sms.`} classes={{
                    tooltip: "tooltip-red",
                    arrow: `tooltip-arrow-red`
                }} enterDelay={1000} arrow>
                    <IconButton onClick={() => setSendSms(!sendSms)} color="inherit">
                        {sendSms ? <Message /> : <SpeakerNotesOff />}
                    </IconButton>
                </Tooltip>}

                {!incident?.internal && <Tooltip title={`${currentLocation ? "Nuvarande" : currentDepartment.name} position`}
                    classes={{
                        tooltip: "tooltip-red",
                        arrow: `tooltip-arrow-red`
                    }} enterDelay={1000} arrow>
                    <IconButton onClick={() => {
                        setCurrentLocation(!currentLocation);
                        setAlertStatus(true);
                    }} color="inherit">
                        {currentLocation ? <Place /> : <MapsHomeWork />}
                    </IconButton>
                </ Tooltip>}
            </>
        }
        disabled={!!response}>

        {/* Content  */}
        {!param && <div className="fade-in d-column">

            {/* Only for development mode. Test mode to writa receivers emails */}
            {developmentMode && <div className="data-container d-row" style={{ marginBottom: developmentMode ? "50px" : "20px" }}>
                <TextField
                    id="subcategories"
                    className="test-mode-emails"
                    label="Mottagare"
                    name="receivers"
                    value={receivers.toString()}
                    placeholder="Din text här ..."
                    multiline
                    onChange={inputChangeHandler}
                    helperText="E-postadresser för test meddelande, separera med kommatecken ..." />
            </div>}

            {/* Default messages */}
            {messages && messages.map((msg, index) => (
                <div key={index} style={{ width: "100%" }}>

                    {/* Message button */}
                    {(confirmId !== index) && <Button variant="contained"
                        onClick={() => confirmHandle(msg, index)} className={`msg-text d-row ${incident.messageType}`}>
                        <span dangerouslySetInnerHTML={{ __html: msg.replaceAll("\n", "<br/>") }}></span>
                    </Button>}

                    {/* Confirm  */}
                    {confirmId === index &&
                        <div className="d-column fade-in response-wrapper" style={{ marginTop: 0 }}>
                            {/* Confirm window*/}
                            {!response && <FormButtons
                                confirm={true}
                                value="Skicka"
                                propsClass="d-column confirm-msg-buttons"
                                clickHandle={reset}
                                loading={loading}
                                send={sendNotification} />}

                            {/* Response */}
                            {response && <Response key={index} msg={response?.message} error={response?.error}
                                reset={reset} />}
                        </div>}
                </div>
            ))}

            {/* Is empty, message */}
            {messages?.length === 0 && <Alert color="error" severity="error">
                Inga färdiga meddelande för denna kategori</Alert>}
        </div>}

        {/* Child component */}
        <Outlet context={[sendNotification, response]} />

        {/* Modal with message about which location will be sent with the notification */}
        <div className={"d-row alert-status " + (alertStatus ? " status-visible" : "")}
            style={{ justifyContent: "space-between" }}>
            {currentLocation ? <Place /> : <MapsHomeWork />}
            <span>Platsdata är {currentLocation ? "nuvarande" : currentDepartment.name} position</span>
        </div>
    </BoxWrapper>
}

export default Messages;