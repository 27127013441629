
import React, { useEffect, useState } from 'react';

// Installed 
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Services
import ApiRequest from '../services/ApiRequest';

function Home({ currentDepartment }) {
  Home.displayName = "Home";

  const navigate = useNavigate();

  const [list, setList] = useState();

  useEffect(() => {
    getIncidentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDepartment])


  const getIncidentsList = async () => {
    await ApiRequest(`incident/department/${currentDepartment?.id}`).then(res => {
      setList(res.data);
    }, error => {
      console.error("Get incidents => " + error);
      if (error.response.status === 401)
        navigate("/session/expired");
    })
  }

  if (!currentDepartment) return null;

  return (
    <div className='container-inside'>
      {!!list && list?.map((item, index) => (
        <Button key={index} id="categories" variant="contained"
          onClick={() => navigate(`/${item.messageType}/incident/${item.id}`)}
          className={`fade-in incident-ctg d-row ${item.messageType}`}>
          {item.name}
        </Button>
      ))}
    </div>
  );
}

export default Home;