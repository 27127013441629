import { useEffect, useState } from "react";

// Installed 
import { Close, Done, Drafts, Mail, NotificationImportant } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import UseAnimations from "react-useanimations";
import alertTriangle from "react-useanimations/lib/alertTriangle";

// Services
import ApiRequest from "../services/ApiRequest";

function NotificationsList({ notification, email, popup, authorized, login, reset }) {
    NotificationsList.displayName = "NotificationsList";

    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [reload, setReload] = useState(false);
    const [warning, setWarning] = useState(false);

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {
        if (!authorized) {
            (async () => {
                const res = await ApiRequest(`notification/check/current/${email}`);
                setWarning(res.data)
                setHidden(res.data)
            })();
        } else
            getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification, email, authorized])

    useEffect(() => {
        if (!authorized) return;

        setVisible(false);
        reset();
        if (loc.pathname?.indexOf("settings") === -1 && loc.pathname?.indexOf("view/notification") === -1)
            getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc])

    useEffect(() => {
        setTimeout(() => { setHidden(!visible); }, 2000)
    }, [visible])

    const getNotifications = async (visible) => {
        setReload(true);

        if (!email) return;

        await ApiRequest(`notification/current/by/${email}`).then(res => {
            const { warnings, notifications, warning } = res?.data;
            if (!!res) {
                setWarning(warning);
                setList([...warnings, ...notifications]);
                setVisible(!!visible);
            } else {
                setWarning(false);
                setList([]);
            }
            setReload(false);
        }, error => {
            console.error("Get current notifications => " + error);
            if (error.response.status === 401)
                navigate("/session/expired");
        });
    }

    const navigateTo = (item) => {
        setVisible(false);
        navigate(`view/notification/${item.type}/${item.id}`);
    }

    const modalListHandle = () => {
        if (!authorized) return;
        reset();
        setVisible(!visible);
    }

    if (!authorized && warning)
        return <IconButton className="notification-btn" style={{ position: "fixed", bottom: "5px", right: "5px" }} onClick={login}>
            <UseAnimations animation={alertTriangle} size={window.innerWidth < 485 ? 30 : 54}
                fillColor="#FFFFFF" strokeColor="#FFFFFF" /></IconButton>;
    else if (!authorized || !email || !!popup)
        return null;

    return (
        /* List of current notifications */
        <div className={`notifications-container d-row ${(visible ? "visible-notifications" : "")}`}
            style={{ zIndex: (visible || !hidden) ? 3000 : 0 }}>

            {/* Button to switch visible of notifications block */}
            {list?.length > 0 && <IconButton className="notification-btn" onClick={() => visible ? modalListHandle() : getNotifications(true)}>
                {visible ? <Close fontSize="large" /> : (reload ? <CircularProgress size={25} />
                    : (warning ? <UseAnimations animation={alertTriangle} size={window.innerWidth < 485 ? 30 : 54}
                        fillColor="#FFFFFF" strokeColor="#FFFFFF" /> : <NotificationImportant fontSize="large" />))}
            </IconButton>}

            {/* Notifications receivedList */}
            <div className="d-column">
                <div className="notification-wrapper">
                    <div className="d-column">
                        {list?.map((item, index) => {
                            return <div className={`notification-block ${item?.type}${!item?.open ? "-unread" : ""}`}
                                key={index} onClick={() => navigateTo(item)}>
                                <h4 className="d-row">
                                    <span>{item?.incident}</span>
                                    {item?.done && <Done />}
                                    {!item.open ? <Mail /> : <Drafts style={{ color: "#FFFFFF" }} />}
                                </h4>
                                <p>- {item?.text}</p>
                                <p>{item?.department}</p>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsList;