
import { useEffect, useRef, useState } from "react";
import _ from 'lodash';

// Installed
import { Autocomplete, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DateRange, Delete, DevicesOther, MobileFriendly, MobileOff } from "@mui/icons-material";

// Components
import FormButtons from "./FormButtons";
import Loading from "./Loading";
import ListSelect from "./ListSelect";
import Response from "./Response";

// Functions
import { DecodedToken } from "../functions/DecodeToken";

// Services
import ApiRequest from "../services/ApiRequest";

function FormUserData({ register, returnDepartment, title, permission, employee }) {
    FormUserData.displayName = "FormUserData";

    const [departments, setDepartments] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [isMember, setMember] = useState(false);
    const [devices, setDevices] = useState([]);
    const [devicesToRemove, setDevicesToRemove] = useState([]);
    const [response, setResponse] = useState(false);
    const [inputted, setInputted] = useState("");
    const [selectedList, setSelectedList] = useState([]);

    const defaultFormData = {
        id: "",
        name: register ? DecodedToken().name : "",
        email: register ? DecodedToken().upn : "",
        department: "",
        departmentId: "",
        support: false,
        fake: false,
        hash: "",
        connectedDepartments: [],
        currentUserEmail: DecodedToken().upn
    };

    const checkboxes = [
        { name: "support", label: "Support" },
        { name: "fake", label: "Test konto" }
    ]
    const [formData, setFormData] = useState(defaultFormData)
    const { editId, viewId } = useParams();
    const navigate = useNavigate();
    const loc = useLocation();
    const refForm = useRef(null);

    useEffect(() => {
        setLoading(!!viewId || !!editId);
        setSelectedList([]);
        (async () => {
            await ApiRequest("department/unsorted").then(res => {
                setLoading(false);
                setDepartments(res.data);
            }, error => {
                console.warn(error);
                if (error.response.status === 401)
                    navigate("/session/expired");
            })
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!editId && !viewId) {
            setFormData(defaultFormData);
            return;
        } else {
            setTimeout(() => {
                (async () => {
                    await ApiRequest("employee/view/data/" + (editId || viewId)).then(res => {
                        if (res.status === 200 && res?.data !== null && typeof res.data !== 'string') {
                            setLoading(false);
                            setFormData(res.data?.employee);
                            setMember(res.data?.developingGroup)
                            setDevices(res.data?.devices);
                            setSelectedList(res.data?.employee.connectedDepartments);
                        } else {
                            let responseData = res;
                            responseData.hide = true;
                            setResponse(responseData);
                        }
                    }, error => {
                        console.warn(error);
                        if (error.response.status === 401)
                            navigate("/session/expired");
                    })
                })();
            }, 100)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editId, viewId, loc])

    const listFilter = (e) => {
        const value = e.target.value;
        setInputted(value);
        if (formData.department?.length === 0)
            setOpen(true);
    }

    const onValueChange = (e, option) => {
        setFormData({ ...formData, department: option?.name || "" })
        if (!register && option?.name && selectedList?.indexOf(option?.name) === -1)
            setSelectedList([...selectedList, option?.name])
    }

    const deleteNotificationDepartment = (name) => {
        setSelectedList(selectedList.filter(x => x !== name))
    }

    const clickHandle = () => {
        if (!!viewId)
            navigate(loc.pathname.replace("view", "edit"), { replace: true })
        else
            setConfirm(!confirm);
    }

    // Add new or edit existing user
    const onSubmit = async (e) => {
        e.preventDefault();

        setConfirm(false);
        setLoading(true);
        let data = formData;

        data.connectedDepartments = selectedList;
        data.devices = devicesToRemove;
        if (selectedList.indexOf(data.department) === -1)
            data.connectedDepartments.push(data.department);


        await (!!editId ? ApiRequest("employee/" + editId, "put", data)
            : ApiRequest("employee", "post", data)).then(res => {
                if (res.status === 200 || typeof res.data === "boolean") {
                    if (res.data === false)
                        navigate("/logout");
                    setFormData(defaultFormData);
                    if (register)
                        returnDepartment(departments.find(x => x.name === data.department));
                }
                if (!register)
                    setResponse(res);
                setLoading(false);
            }, error => {
                if (error.response.status === 401)
                    navigate("/session/expired");
                setLoading(false);
                setResponse(error);
            })
    }

    const deleteDevice = async (id) => {
        setDevicesToRemove(oldList => [...oldList, id]);
        setDevices(devices.filter(x => x.id !== id));
    }

    const resetResponse = () => {
        if (response.status === 200 && !response.data)
            setFormData(defaultFormData);
        setResponse(null);
    }


    if (!!response)
        return <Response msg={(!!response?.data?.message ? response?.data?.message : response?.data)} error={response.data?.error} reset={response?.hide ? undefined : resetResponse} />;

    return <div className="fade-in form-wrapper">
        <form className="user-data" onSubmit={onSubmit} ref={refForm}>
            <h3>{title}</h3>

            {/* If it is not a new registration */}
            {!register && [{ label: "Namn", input: "name" },
            { label: "E-post", input: "email" }].map((x, i) => (
                <TextField
                    key={i}
                    name={x.input}
                    label={x.label}
                    value={formData[x.input] || ""}
                    required
                    disabled={confirm || !!editId || !!viewId}
                    onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                    placeholder="Din text här ..."
                />))}

            {/* Text field for departments list */}
            <Autocomplete
                freeSolo
                clearOnEscape
                options={departments.filter(x => x.name.toLowerCase().includes(inputted.toLowerCase()))}
                getOptionLabel={(option) => option.name || ""}
                autoHighlight
                onChange={(e, option) => onValueChange(e, option)}
                inputValue={formData.department || inputted}
                onInputChange={(event, newInputValue) => {
                    setOpen(false);
                    if (!newInputValue && !viewId) {
                        setOpen(true);
                        setInputted("");
                        if (!register && selectedList?.indexOf(formData.department) > -1)
                            setSelectedList(selectedList.filter(x => x !== formData.department))
                        setFormData({ ...formData, department: "" })
                    }
                }}
                onBlur={() => setOpen(false)}
                // onClose={() => setOpen(false)}
                onFocus={() => setOpen(true)}
                open={open}
                disabled={!register && !permission}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name="department"
                        style={(!formData.department && inputted) ? { opacity: 0.4 } : {}}
                        label="Arbetsplats"
                        required
                        InputProps={{
                            ...params.InputProps,
                            maxLength: 30,
                            minLength: 2
                        }}
                        disabled={confirm || loading || !!viewId}
                        placeholder="Välj från listan ..."
                        onChange={listFilter}
                    />} />

            {/* List of department to get notification from */}
            {(!register && selectedList?.length > 0 && permission && !response) && <>
                <div className="list-wrapper">
                    <p>Arbetsplatser att få avisering ifrån.</p>
                    {selectedList?.map((item, index) => {
                        const disabled = formData.department === item;
                        return <div key={index} className="list-div d-row">
                            • {item}
                            {!viewId && <Tooltip title={disabled ? "Arbetsplats får inte raderas härifrån." : "Dubbel kilcka att radera arbetsplats."}
                                classes={{
                                    tooltip: "tooltip-default"
                                }}
                                arrow
                                disableTouchListener={disabled}
                                enterDelay={1}>
                                <span>
                                    <IconButton
                                        color="error"
                                        onDoubleClick={() => deleteNotificationDepartment(item)}
                                        size="small"
                                        disabled={disabled}>
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>}
                        </div>
                    })}
                </div>

                {/* Select list with departments */}
                <ListSelect
                    api="department"
                    loading={loading}
                    name="name"
                    multiple={true}
                    selected={selectedList || []}
                    matchParameter="name"
                    width="100%"
                    label="Välj ytterligare arbetsplats ..."
                    disabledItem={formData?.department}
                    handleChange={(value) => setSelectedList(value)} />
            </>}

            {/* User data only for view page */}
            {!register && <>
                {/* Register date */}
                {!!viewId && <TextField InputProps={{
                    startAdornment: (
                        <InputAdornment position="start"><DateRange />  </InputAdornment>
                    )
                }} name="date" label="Datum" value={formData?.date || ""} disabled />}

                {/* Devices list */}
                {(!!editId || !!viewId) && <TextField InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {devices?.length > 1 ? <DevicesOther color="inherit" /> :
                                (devices?.length === 1 ? <MobileFriendly color="inherit" /> : <MobileOff color="disabled" />)}
                        </InputAdornment>
                    )
                }} name="app" label="App laddat ner på"
                    value={`${devices?.length} enhet(er)`}
                    onClick={() => setDropdown(!dropdown)}
                    disabled
                    helperText={devices?.length === 0 ? "" : ("Klicka här för att  " + (!dropdown ? "visa" : "dölja") + " enhets list")} />}

                {/* List of employee's devices */}
                {devices?.length > 0 && <div className={(dropdown ? "dropdown-visible" : "dropdown-hidden")}>
                    {devices?.map((item, index) => (
                        <div key={index} className="list-div d-row">
                            <p className="d-row" style={{ justifyContent: "space-between", width: "90%" }}>
                                <span>• {item.nameId}</span>
                                <span>{item.date}</span>
                            </p>
                            <Tooltip title="Dubbel kilcka att radera denna enhet."
                                classes={{
                                    tooltip: "tooltip-default"
                                }} enterDelay={1000} arrow>
                                <span>
                                    <IconButton color="error" onDoubleClick={() => deleteDevice(item.id)} disabled={!!viewId} size="small">
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>))}
                </div>}
            </>}

            {/* If the user is a member of the development team */}
            {(!!editId && isMember === formData?.email) && <TextField
                name="hashCode"
                label="Hemlig password"
                type="password"
                value={formData.hashCode}
                disabled={confirm}
                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                placeholder="Din text här ..."
            />}

            {!register && checkboxes?.map((item, i) => {
                return <FormControlLabel
                    key={i}
                    name={item?.name}
                    control={<Checkbox
                        id={item?.name + "_" + i}
                        name={item?.name}
                        size="small"
                        disabled={open || confirm || !!viewId || !permission}
                        checked={formData[item?.name]}
                        onChange={() => setFormData({ ...formData, [item?.name]: !formData[item?.name] })}
                        value={formData[item?.name]} />} label={item?.label} />
            })}

            <FormButtons
                confirm={confirm}
                value={!!viewId ? "Ändra" : "Spara"}
                disabled={(!viewId && (_.isEqual(defaultFormData, formData)) && devicesToRemove.length === 0) || loading || open}
                clickHandle={clickHandle}>
                {(!confirm && !loading && !!editId && (permission || employee?.support)) && <Button variant="contained" color="error"
                    style={{ marginRight: "10px" }} type="button" onClick={() => navigate(`/settings/employees/block/${formData?.id}`)}>
                    Blockera
                </Button>}
            </FormButtons>
        </form>

        {loading && <Loading color="primary" size={35} />}
    </div>
}

export default FormUserData;