
import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';

// Installed
import { Alert, AlertTitle, Box, Button, FormLabel, IconButton, Modal, TextField, TextareaAutosize, Tooltip, Typography } from '@mui/material';
import { Close, Edit, HelpCenterRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Components
import Loading from '../components/Loading';
import Buttons from '../components/Buttons';
import FormButtons from '../components/FormButtons';
import Response from '../components/Response';
import ListSelect from '../components/ListSelect';

// Services
import ApiRequest from '../services/ApiRequest';

// Json
import information from '../assets/json/information.json';

function Configuration({ edit }) {

    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [inputs, setInputs] = useState();
    const [formData, setFormData] = useState({});
    const [defaultData, setDefaultData] = useState({});
    const [web, setWeb] = useState();
    const [hashPassword, setHashPassword] = useState("");
    const [modal, setModal] = useState(false);
    const [counter, setCounter] = useState(60);
    const [response, setResponse] = useState();
    const [selected, setSelected] = useState("");
    const [selectedField, setSelectedField] = useState("");
    const [restore, setRestore] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);

    const navigate = useNavigate();
    const ref = useRef(null);

    useEffect(() => {

        setLoading(true);
        async function getConfiguration() {
            const res = await ApiRequest("data/configuration");
            setLoading(false);
            setInputs(res?.data);
        }

        getConfiguration();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setSelected("");
        setSelectedField("");
    }, [edit, restore])

    useEffect(() => {
        if (!inputs || !!edit) return;
        let data = {};
        inputs.forEach(input => {
            var inp = (input.charAt(0).toLowerCase() + input.slice(1)).replaceAll(" ", "");
            data[inp] = "";
        });
        setFormData(data);
        setDefaultData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs])

    useEffect(() => {
        let count = 60;

        const intervalTimer = setInterval(() => {
            if (!modal) {
                clearInterval(intervalTimer);
            } else if (count === 0 && modal) {
                clearInterval(intervalTimer);
                navigate("/");
            }

            setCounter(count--);
        }, 1000)

        return () => clearInterval(intervalTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal])

    const handleSelectValueChange = async (res) => {
        setWeb(res?.hostName);
        if (!res.restored) {
            setFormData({ name: res?.name })
            setRestore(true);
        } else
            setFormData({ ...formData, name: res?.name })
        setSelected(res?.id);
    }

    const changeHandle = (e) => {
        if (!e.target) return;
        setFormData({ ...formData, [e.target.name]: e.target?.value.replaceAll("  ", "") });
    }

    const confirmHandle = () => {
        setConfirm(!confirm);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setConfirm(false);
        setModal(!modal);

        if (!modal) return;

        let data = formData;

        if (typeof data?.scopes === "string")
            data.scopes = data?.scopes.split(",");
        if (typeof data?.departments === "string")
            data.departments = data?.departments.split(",");

        if (!!edit) {
            // If configuration wil be updated
            const name = (selectedField.charAt(0).toLowerCase() + selectedField.slice(1)).replaceAll(" ", "");
            data = {
                name: data.name,
                parameter: selectedField.replaceAll(" ", ""),
                value: formData[name],
                configurationFileName: data.configurationFileName
            }
        } else if (restore) {
            // If configuration file will be restored
            data = {
                name: data.name,
                configurationFileName: "appsettings.json"
            }
        }
        data.authenticationString = `https://${web}/employee/request/${hashPassword}`;

        await fetch(`https://${web}/data/configuration${!!edit ? "/update" : (restore ? "/restore" : "")}`, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify(data)
        }).then(async res => {
            setLoading(false);
            setRestore(false);
            const { message } = await res.json();
            if (res.status === 200 && !message) {
                setResponse(res);
                setFormData(defaultData);
            } else
                setResponse({ message: message, error: true });
        }, error => {
            setLoading(false);
            console.error(error);
            if (error.response.status === 401)
                navigate("/session/expired");
        })
    }

    const reset = (value) => {
        if (!!value)
            setRefresh(true);
        else
            setResponse(null);
    }

    return (
        <div className="container-inside">
            <div className="box d-column">

                {/* Panel */}
                <div className="box-menu d-row">
                    {/* Heading */}
                    <div className="box-header-wrapper">
                        <h3>Konfiguration</h3>
                        <p>Utvecklarens panel</p>
                    </div>

                    <Buttons useRefresh={true} refresh={refresh}>
                        {/* Change configuration filed */}
                        {!edit && <Tooltip title="Ändra utvalda parameter"
                            classes={{
                                tooltip: "tooltip-default"
                            }} enterDelay={1000} arrow>
                            <IconButton color="primary" onClick={() => navigate("edit")} >
                                <Edit />
                            </IconButton>
                        </Tooltip>}
                    </Buttons>
                </div>

                {!!response && <Response msg={response?.message} error={response?.error} reset={reset} />}

                {(!!inputs && !response) && <div className="form-wrapper">
                    <h3 className='d-row form-label'>
                        Konfiguration data
                        <IconButton onClick={() => setInfoVisible(!infoVisible)}>
                            {!infoVisible ? <HelpCenterRounded color="info" /> : <Close color="error" />}
                        </IconButton>
                    </h3>

                    {/* Info block */}
                    <div className={infoVisible ? "dropdown-visible dropdown-info" : "dropdown-hidden"}
                        dangerouslySetInnerHTML={{ __html: information?.configuration?.municipality }}></div>

                    {/* Municipalities list */}
                    <ListSelect api={"municipality"} label="Kommunen" name="name" width="100%"
                        matchParameter="id" selected={selected} handleChange={handleSelectValueChange} />

                    {!!edit && <>
                        {/* Info block */}
                        <div className={infoVisible ? "dropdown-visible dropdown-info" : "dropdown-hidden"}
                            dangerouslySetInnerHTML={{ __html: information?.configuration?.variable }}></div>

                        {/* Configurations fields list  */}
                        <ListSelect
                            disabled={loading || !alert}
                            width="100%"
                            name="name"
                            label="Variabel"
                            readyList={inputs}
                            selected={selectedField}
                            handleChange={(value) => setSelectedField(value)} />
                    </>}

                    {/* Form */}
                    <form onSubmit={onSubmit} style={{ marginTop: 20 }}>

                        {(!restore && !!formData) &&
                            (!edit ? inputs : inputs.filter(x => x === selectedField || x.replaceAll(" ", "") === "ConfigurationFileName")).map((inp, ind) => {
                                const disabled = confirm || loading;
                                const name = (inp.charAt(0).toLowerCase() + inp.slice(1)).replaceAll(" ", "");

                                if (name === "name")
                                    return null;
                                else {
                                    return <div className='w100'>
                                        {/* Info block */}
                                        <div className={infoVisible ? "dropdown-visible dropdown-info" : "dropdown-hidden"}
                                            dangerouslySetInnerHTML={{ __html: information?.configuration[name] ?? information?.configuration[formData[name]] }}></div>

                                        {(["departments", "scopes", "databaseConnectionString"].indexOf(name) > -1) ? <div className='form-textarea-wrapper' key={ind} style={{ opacity: disabled ? 0.6 : 1 }}>
                                            <FormLabel className="form-textarea-label">{inp} *</FormLabel>
                                            <TextareaAutosize
                                                className="form-textarea"
                                                label={inp}
                                                name={name}
                                                required
                                                id="form"
                                                defaultValue={formData[name] || ""}
                                                placeholder="Din text här..."
                                                minRows={4}
                                                disabled={disabled}
                                                onChange={changeHandle}
                                            />
                                        </div> : <TextField key={ind}
                                            className="form-fields"
                                            label={inp}
                                            name={name}
                                            required={["googlePlayLink", "appStoreLink", "developerGroupId"].indexOf(name) === -1}
                                            id="form"
                                            value={formData[name] || ""}
                                            placeholder={inp.toLowerCase().indexOf("expires") > -1 ? "Format: 04/12/2023" : (name === "configurationFileName" ? "Standard: appsettings.json" : "Din text här...")}
                                            disabled={disabled || name === "name"}
                                            onChange={changeHandle} />}
                                    </div>
                                }
                            })}

                        {/* Restore configuration file */}
                        {restore && <Alert variant='outlined' color='error' severity="error" className='alert-wrapper'>
                            <AlertTitle style={{ marginBottom: 0 }}>Konfiguration file återställning krävs.</AlertTitle>
                        </Alert>}

                        {/* Info block */}
                        <div className={infoVisible ? "dropdown-visible dropdown-info" : "dropdown-hidden"}
                            dangerouslySetInnerHTML={{ __html: information?.configuration.more }}></div>

                        {/* Buttons */}
                        <FormButtons confirm={confirm}
                            disabled={(_.isEqual(defaultData, formData) || loading) && !restore}
                            value={!restore ? "Spara" : "Återställa"}
                            clickHandle={confirmHandle} />

                        <Button type="submit" ref={ref} style={{ display: "none", zIndex: -1 }} />
                    </form>

                    {/* Modal for confirm the action with password */}
                    <Modal open={modal}
                        onClose={() => setModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            color: "#000000",
                            backgroundColor: '#FFFFFF',
                            border: "1px solid #cc0000",
                            boxShadow: 24,
                            p: 4,
                        }} >
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Bekräfta med password.
                            </Typography>

                            <Typography id="modal-modal-description" sx={{ mt: 2 }} className='modal-counter'>
                                Fönstret stängs om <span>{counter}</span> second
                            </Typography>

                            <TextField className="modal-field" label="Password" type="password" value={hashPassword} onChange={(e) => setHashPassword(e.target.value)} />
                            <Button variant="outlined" color='inherit'
                                disabled={hashPassword?.length <= 3}
                                style={{ float: "right" }} onClick={() => ref?.current.click()}>Skicka</Button>
                        </Box>
                    </Modal>

                </div>}

                {!inputs && loading && <Loading color="error" size={30} />}
            </div>
        </div>
    )
}

export default Configuration;