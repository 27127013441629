
import { useEffect, useState } from "react";

// Install
import { BroadcastOnHomeOutlined, Cable, MobileFriendly, Web } from "@mui/icons-material";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

// Components
import BoxWrapper from "../components/BoxWrapper";

// Services
import ApiRequest from "../services/ApiRequest";


function Connections({ department, connected, connection }) {
    Connections.displayName = "Connections";

    const [loading, setLoading] = useState(true);
    const [connections, setConnections] = useState([
        { name: "webbsidan", img: <Web color="#FFFFFF" />, data: [] },
        { name: "mobilapp", img: <MobileFriendly color="#FFFFFF" />, data: [] }
    ]);


    useEffect(() => {
        (async () => {
            await ApiRequest("data/connections").then(res => {
                const data = res.data;
                if (!!data) {
                    let newConnections = [...connections];
                    newConnections[1].data = data;
                    setConnections(newConnections);
                }
                setLoading(false);
             }, error => {
                console.error("Check connection error => " + error);
                setLoading(false);
            });
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (!connection) return;

        (async () => {
            connection?.on("ConnectedEmployees", connected => {
                let newConnections = [...connections];
                newConnections[0].data = connected;
                setConnections(newConnections);
            });
            await connection.invoke("UpdateConnections");
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection])

    useEffect(() => {
        if (!connected) return;

        let newConnections = [...connections];
        newConnections[0].data = connected;
        setConnections(newConnections);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected])


    return <BoxWrapper loading={loading}
        label="Antal anslutna anställda"
        subLabel={department?.name} img={<BroadcastOnHomeOutlined />}>
        {connections?.map((item, index) => {
            return <List className="fade-in list-li-wrapper" key={index}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar variant="circular" className="avatar" color="#FFFFFF">
                            {item.img}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Anslutna personer via ${item.name}`} secondary="Egen anslutning räknas inte." color="#cc0000" />
                </ListItem>
                <ListItem className="list-li-names d-column">
                    {!!item?.data && item?.data.map((d, ind) => {
                        const count = (d?.connected || d?.connectedApp) || 0;
                        return <ListItemText
                            key={ind}
                            primary={<>
                                <span className="d-row"><Cable color={d.name === department?.name ? "error" : "inherit"} /> {d.name}</span>
                                <span>{count > 0 ? count - 1 : count}</span>
                            </>}
                            color="#FFFFFF" />
                    })}

                    {item.data.length === 0 && <p>Ingen anslutning hittades</p>}
                </ListItem>
            </List>
        })}
    </BoxWrapper >;
}

export default Connections;