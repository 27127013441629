import React, { useState } from 'react'

// Installed
import { Route, Routes } from 'react-router-dom';

// Pages
import MobileDisabled from '../pages/MobileDisabled';
import Start from '../pages/Start';
import SupportManual from '../pages/SupportManual';

// Components
import NotificationsList from '../components/NotificationsList';

function UnauthorizedRoutes({ isBlockedPlatform }) {

    const [login, setLogin] = useState(false);

    const routes = !!isBlockedPlatform ? <MobileDisabled {...isBlockedPlatform} /> : <Start login={login} />;
    const email = localStorage.getItem("employeeEmail");
    return (
        <>
            <Routes>
                <Route path="/" element={routes} />
                <Route path="/start/manual/:id" element={<SupportManual />} />
                <Route path="/*" element={routes} />
            </Routes>
            
            {/* Current notifications list */}
            {!!email && <NotificationsList authorized={false} email={email} login={() => setLogin(true)}/>}
        </>
    )
}

export default UnauthorizedRoutes;