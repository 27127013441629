import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Installed
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Switch } from '@mui/material';
import { ListAltOutlined, LockOutlined, NotificationsActive } from '@mui/icons-material';

// Components
import BoxWrapper from '../components/BoxWrapper';

// Services
import ApiRequest from '../services/ApiRequest';

function Notifications({ department, employee }) {
  Notifications.displayName = "Notifications";

  const [messages, setMessages] = useState([]);
  const [received, setReceived] = useState(true);
  const [loading, setLoading] = useState(true);
  const [sorted, setSorted] = useState([]);
  const [param, setParam] = useState([]);

  const navigate = useNavigate();
  const { list } = useParams();

  useEffect(() => {
    async function getInternalMessages() {
      await ApiRequest(`notification/${list}/department/${department.id}`).then(res => {
        setLoading(false);
        setMessages(res.data);
        setSorted(res?.data?.filter(x => x.senderId !== employee?.id));
      })
    }

    getInternalMessages();
    setParam(list === "warnings" ? list.slice(0, -1) : list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, list])


  const sortList = () => {
    setSorted(messages.filter(x => !received ? x.senderId !== employee?.id : x.senderId === employee?.id));
    setReceived(!received);
  }


  return <BoxWrapper loading={loading} label={list === "internal" ? "Intern meddelande" : "Varningar"}
    subLabel={department?.name} img={<ListAltOutlined />}
    styles={`color-${param}`}>
    <List className="fade-in notifications-list-wrapper">

      <ListItem className='d-row list-panel'>
        <span>Antal: {sorted?.length || 0}</span>

        <span>
          {received ? "Mottagen" : "Skickade"}
          <Switch onChange={sortList} color={list === "internal" ? "warning" : "error"} checked={received} />
        </span>

      </ListItem>

      {sorted?.map((item, index) => {
        return <ListItem key={index}
          className={`${!item.done ? item?.type : "solved"}${(!item?.done && !item?.open && received) ? ` unread-${item.type}` : ''}`}
          onClick={() => navigate(`/view/notification/${item.type}/${item?.id}`)}
          sx={{
            borderColor: item.done ? "#FFFFFF !important" : "inherit",
            cursor: "pointer"
          }}>
          <ListItemAvatar>
            <Avatar variant="circular" className="avatar">
              {item.done ? <LockOutlined /> : <NotificationsActive />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.text + item?.type} secondary={item?.date} />
        </ListItem>
      })}

      {sorted?.length === 0 && <ListItem className="solved">Ingen meddalande</ListItem>}
    </List>

  </BoxWrapper>;
}

export default Notifications;