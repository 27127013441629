// Installed
import { Button } from "@mui/material";

// Components
import Loading from "./Loading";

function FormButtons({ children, confirm, disabled, propsClass, clickHandle, send, value, loading }) {
    FormButtons.displayName = "FormButtons";


    return (<div className={!!propsClass ? propsClass + " form-btn-container" : "form-btn-container d-screen"}>
        
        {/*  Buttons */}
        {(confirm && !loading) && <>
            <p className="confirm-question">{`${value}?`}</p>
            <div className="d-row">
                <Button variant="outlined" type="button" color="inherit" onClick={clickHandle}>Nej</Button>
                {!send && <Button variant="contained" type="submit" color="error">Ja</Button>}
                {!!send && <Button variant="contained" onClick={send} color="error">Ja</Button>}
            </div>
        </>}
        
        {/* Children block */}
        {children && children}

        {/* Confirm block */}
        {(!confirm && !loading) && <Button variant="outlined" color="inherit" type="button"
            onClick={clickHandle} disabled={disabled}>
            {value}
        </Button>}

        {loading && <Loading color="error" />}
    </div>)
}

export default FormButtons;